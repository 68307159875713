import request from '../../../components/Infrastructure/Requests/Request';
import pubsub from 'pubsub-js';
import {UI_EVENT} from '../../../components/Shared/Constants/Events';
import {SensorsAllInformationsViewRow} from '../../types/DbModel';
import Authentication from '../../../components/Infrastructure/Authentication/Authentication';
import {DiscoverModuleResponse} from './DiscoverModuleResponse';
import {SensorInfo} from 'components/Services/Sensors/SensorInfo';
import {IServerSideGetRowsRequest} from 'ag-grid-enterprise';
import dayjs from 'dayjs';
import {Sensor} from '../../../components/Common/Types/Sensor';

export class SensorService {
	public static async DiscoverModule(serialNumber: string): Promise<DiscoverModuleResponse> {
		try {
			const response = await request({
				url: '/rpc/discover_module',
				method: 'POST',
				data: {serial_number: serialNumber},
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}
	public static async DiscoverRun(sensorId: number): Promise<{AffectedSensors: {id: number; name: string}[]; Configuration: object}> {
		try {
			const response = await request({
				url: '/rpc/discover_run',
				method: 'POST',
				data: {s_id: sensorId},
				token: Authentication.getToken(),
			});
			return {AffectedSensors: response.data.affected_sensors, Configuration: response.data.configuration};
		} catch (e) {
			console.error(e);
			return await Promise.reject(e);
		}
	}
	public static async SetAlarmingEnabled(sensor: Sensor, alarmingEnabled: boolean): Promise<void> {
		try {
			await request({
				url: '/rpc/activate_alarming',
				method: 'POST',
				token: Authentication.getToken(),
				data: {
					sensors_id: sensor.Id,
					activate: alarmingEnabled,
				},
			});
			pubsub.publish(`${UI_EVENT.SENSOR_ALARMING_ENABLED_CHANGED}.${sensor.Id}`, {
				AlarmingEnabled: alarmingEnabled,
				SensorId: sensor.Id,
			});
		} catch (e) {
			console.error(e);
			return Promise.reject(e);
		}
	}

	public static async GetModuleSensors(serial_number: string): Promise<SensorInfo[]> {
		try {
			const response = await request({
				url: '/sensors_info?serial_number=eq.' + serial_number,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			return await Promise.reject(new Error('Failed to get module sensors'));
		}
	}

	public static async GetSensorInfo(sensorId: number): Promise<SensorInfo> {
		try {
			const response = await request({
				url: '/sensors_info?id=eq.' + sensorId,
				method: 'GET',
				token: Authentication.getToken(),
			});
			return response.data[0];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get SensorInfo'));
		}
	}

	public static async GetSensorsFromGridInfo(params: IServerSideGetRowsRequest): Promise<{total: number; sensors: SensorInfo[]}> {
		let sortParms = '';
		params.sortModel.forEach(sortDefinition => {
			sortParms += `&order=${sortDefinition.colId.toLowerCase()}.${sortDefinition.sort}.${
				sortDefinition.sort === 'asc' ? 'nullsfirst' : 'nullslast'
			}`;
		});

		let filterParams = '';
		if (params.filterModel) {
			Object.keys(params.filterModel).forEach(function (key) {
				const item = params.filterModel[key];

				let setupFilter = null;
				switch (item.filterType) {
					case 'text':
						setupFilter = SensorService.setupTextFilter;
						break;
					case 'number':
						setupFilter = SensorService.setupNumberFilter;
						break;
					case 'date':
						setupFilter = SensorService.setupDateFilter;
						break;
					case 'set':
						setupFilter = SensorService.setupSetFilter;
						break;
					default:
						console.log('unknown filter type: ' + item.filterType);
						break;
				}

				if (setupFilter) {
					filterParams += item.operator
						? SensorService.mapFilters(key, item, setupFilter)
						: SensorService.mapFilter(key, item, setupFilter);
				}
			});
		}

		if (params.groupKeys) {
			params.groupKeys.forEach(function (key, i) {
				filterParams += `&${params.rowGroupCols[i].id}=eq.${key}`;
			});
		}

		const res = await request({
			url: `/sensors_info?limit=${params.endRow - params.startRow}&offset=${params.startRow}${sortParms}${filterParams}`,
			method: 'GET',
			token: Authentication.getToken(),
			headers: {Prefer: 'count=exact'},
		});

		return {
			total: res.headers['content-range'].split('/')[1],
			sensors: res.data,
		};
	}

	public static async GetChannelIds(): Promise<number[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=channel_id',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<number>(response.data.map((d: {channel_id: number}) => d.channel_id))];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get channel ids'));
		}
	}

	public static async GetDeviceStates(): Promise<string[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=device_state',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<string>(response.data.map((d: {device_state: string}) => d.device_state))];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get device states'));
		}
	}

	public static async GetModuleShortNames(): Promise<string[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=module_short_name',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<string>(response.data.map((d: {module_short_name: string}) => d.module_short_name))];
		} catch (e) {
			return await Promise.reject(new Error('Failed to get module short names'));
		}
	}

	public static async GetSensorFamilies(): Promise<string[]> {
		try {
			const response = await request({
				url: '/sensors_overview?select=module_family',
				method: 'GET',
				token: Authentication.getToken(),
			});
			return [...new Set<string>(response.data.map((d: {module_family: string}) => d.module_family))];
		} catch (e) {
			console.error(e);
			return await Promise.reject(new Error('Failed to get sensor families'));
		}
	}

	public static async AllInformation(sensorId: number): Promise<SensorsAllInformationsViewRow> {
		try {
			const response = await request({
				url: '/sensors_all_informations?id=eq.' + sensorId,
				method: 'GET',
				headers: {Accept: 'application/vnd.pgrst.object'},
				token: Authentication.getToken(),
			});
			return response.data;
		} catch (e) {
			return Promise.reject(e);
		}
	}

	private static mapFilters(
		key: any,
		item: {conditions: {type: string; filter: any}[]; operator: any},
		setupFilter: (key: any, item: any) => string
	) {
		const conditionList = Object.keys(item)
			.filter(key => key.startsWith('condition'))
			.map(key => item[key]);

		const conditions = conditionList.map((condition: {type: string; filter: any}) => setupFilter(key, condition));
		return `&${item.operator.toLowerCase()}=(${conditions.join(',')})`;
	}

	private static mapFilter(key: any, item: {type: string; filter: any}, setupFilter: (key: any, item: any) => string) {
		return `&and=(${setupFilter(key, item)})`;
	}

	private static setupTextFilter(key: any, item: {type: string; filter: any}) {
		switch (item.type) {
			case 'equals':
				return `${key}.eq.${item.filter}`;
			case 'notEqual':
				return `${key}.isdistinct.${item.filter}`;
			case 'contains':
				return `${key}.ilike.*${item.filter}*`;
			case 'notContains':
				return `${key}.not.ilike.*${item.filter}*`;
			case 'startsWith':
				return `${key}.ilike.${item.filter}*`;
			case 'endsWith':
				return `${key}.ilike.*${item.filter}`;
			case 'blank':
				return `${key}.is.null`;
			case 'notBlank':
				return `${key}.is.not.null`;
			default:
				console.log('unknown text filter type: ' + item.type);
		}
	}

	private static setupNumberFilter(key: any, item: {type: string; filter: any; filterTo: any}) {
		switch (item.type) {
			case 'equals':
				return `${key}.eq.${item.filter}`;
			case 'notEqual':
				return `${key}.neq.${item.filter}`;
			case 'greaterThan':
				return `${key}.gt.${item.filter}`;
			case 'greaterThanOrEqual':
				return `${key}.gte.${item.filter}`;
			case 'lessThan':
				return `${key}.lt.${item.filter}`;
			case 'lessThanOrEqual':
				return `${key}.lte.${item.filter}`;
			case 'inRange':
				return `and(${key}.gte.${item.filter}, ${key}.lte.${item.filterTo})`;
			case 'blank':
				return `${key}.is.null`;
			case 'notBlank':
				return `${key}.is.not.null`;
			default:
				console.log('unknown number filter type: ' + item.type);
		}
	}

	private static setupDateFilter(key: any, item: {type: string; dateFrom: any; dateTo: any}) {
		switch (item.type) {
			case 'equals':
				return `and(${key}.gt.${dayjs(item.dateFrom).add(-1, 'day').format('YYYY-MM-DD')}, ${key}.lt.${dayjs(item.dateFrom)
					.add(1, 'day')
					.format('YYYY-MM-DD')})`;
			case 'notEqual':
				return `or(${key}.lt.${item.dateFrom}, ${key}.gt.${dayjs(item.dateFrom).add(1, 'day').format('YYYY-MM-DD')})`;
			case 'greaterThan':
				return `${key}.gt.${dayjs(item.dateFrom).add(1, 'day').format('YYYY-MM-DD')})`;
			case 'lessThan':
				return `${key}.lt.${item.dateFrom}`;
			case 'inRange':
				return `and(${key}.gte.${item.dateFrom}, ${key}=lte.${item.dateTo})`;
			case 'blank':
				return `${key}.is.null`;
			case 'notBlank':
				return `${key}.is.not.null`;
			default:
				console.log('unknown date filter type: ' + item.type);
		}
	}

	private static setupSetFilter(key: any, item: {values: any[]}) {
		if (key.endsWith('_array')) {
			return `${key}.ov.{${item.values.join(',')}}`;
		}
		return `${key}.in.(${item.values.join(',')})`;
	}
}
