import React, {useEffect, useState} from 'react';

export default props => {
	const [count, setCount] = useState(0);

	useEffect(() => {
		props.api.addEventListener('modelUpdated', updateStatusBar);
		updateStatusBar();
	}, []);

	const updateStatusBar = () => {
		const count = props.api.getDisplayedRowCount();
		setCount(count);
	};

	return (
		<div className="ag-status-name-value">
			<span className="component">Rows: &nbsp;</span>
			<span className="ag-status-name-value-value">{count}</span>
		</div>
	);
};
