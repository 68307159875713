import {ValueFormatterParams} from 'ag-grid-enterprise';
import {AlarmState} from '../../common/constants';

import i18n from 'i18next';
import DateTimeUtils from '../Infrastructure/DateTime/DateTimeUtils';
import dayjs from 'dayjs';
import {SensorInfo} from '../Services/Sensors/SensorInfo';
import {CONSTANTS} from '../../components/Shared/Constants/Constants';

type FormatterParams<V> = ValueFormatterParams<SensorInfo, V>;

export function TranslateGlobalString(s: string): string {
	return i18n.t(`global.${s}`);
}

export function AlarmStateFormatter(v: ValueFormatterParams<SensorInfo, AlarmState>): string {
	if (v?.node.group && v.node.rowGroupColumn !== v.column) {
		return '';
	}

	const alarmState = v.value;
	if (!alarmState || alarmState === AlarmState.None) {
		return TranslateGlobalString(CONSTANTS.DEFAULT_EMPTY);
	}

	return i18n.t(`alarmStates.${alarmState}`).toUpperCase();
}

export function DeviceStateFormatter(v: FormatterParams<string>): string {
	if (v?.node.group && v.node.rowGroupColumn !== v.column) {
		return '';
	}

	return v?.value?.toUpperCase() ?? TranslateGlobalString(CONSTANTS.DEFAULT_EMPTY);
}

export function DefaultCellFormatter(v: FormatterParams<any>): string {
	if (v?.node.group && v.node.rowGroupColumn !== v.column) {
		return '';
	}

	if (!v?.value || v.value === '' || (Array.isArray(v.value) && v.value.length === 0)) {
		return i18n.t('global.noValue');
	}
	return v.value;
}

export function DateTimeFormatter(v: FormatterParams<dayjs.Dayjs | string>): string {
	if (v?.node.group && v.node.rowGroupColumn !== v.column) {
		return '';
	}

	if (dayjs.isDayjs(v?.value) || dayjs(v.value).isValid()) {
		const dateTime = dayjs(v.value);
		return DateTimeUtils.toUserString(dateTime);
	}

	return DefaultCellFormatter(v);
}
