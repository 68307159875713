import React from 'react';
import Barcode from 'react-barcode';
import {FormSummary, Row} from '../../../../../../../components/Wizard/Forms/Summary/FormSummary';

export function ChecksumComponent(props: any): React.JSX.Element {
	if (!props.checksum) return null;

	const rows: Row[] = [
		{
			key: 'sensorWizard.general.checksum',
			value: <Barcode value={props.checksum} height={50} />,
		},
	];
	return <FormSummary title={'sensorWizard.general.summary'} rows={rows} />;
}
