import React from 'react';
import {Link} from 'react-router-dom';
import SensorDropdown from '../SensorDropdown';
import {CaretRightFilled, LoadingOutlined} from '@ant-design/icons';
import {RADIO_STRENGTH_STRINGS} from './SignalStrength/Constants/RadioStrengthEnums';
import {SignalStrengthComponent} from './SignalStrength/SignalStrengthComponent';
import {Col, Row} from 'antd';
import {WarningIcon, WarningIconSettings} from '../../../Common/WarningIcon';
import {SensorInfo} from 'components/Services/Sensors/SensorInfo';

interface SensorHeaderProps {
	fillingColor: string;
	sensor: SensorInfo;
	sensorConfigurationLastStep: any;
}

const SensorHeader = (props: SensorHeaderProps) => {
	const sensor = props.sensor;

	const iconLink = `deviations?moduleId=${sensor.serial_number}`;
	const iconLinkTitle = 'View Deviations';

	const getTitle = () => (sensor.device_state === 'PREPARED' ? 'Sensor not yet started' : sensor.device_state);

	const getIconSettings = (type: 'warning' | 'alarm'): WarningIconSettings | undefined => {
		const isWarning = type === 'warning';

		if ((type === 'alarm' && !sensor.has_alarm) || (type === 'warning' && !sensor.has_warning)) {
			return undefined;
		}

		return {
			isAlarm: !isWarning,
			isInactive: false,
			isAcknowledged: isWarning ? !sensor.has_unacknowledged_warning : !sensor.has_unacknowledged_alarm,
		};
	};

	let alarmIconSettings = getIconSettings('alarm');
	let warningIconSettings = getIconSettings('warning');

	const signalStrength = RADIO_STRENGTH_STRINGS[sensor.signal_strength];

	let headerLeft: React.JSX.Element[] = [];
	let headerRight: React.JSX.Element[] = [];

	if (!sensor.is_retired) {
		if (sensor.is_muted) {
			headerLeft.push(<WarningIcon event={{isAlarm: true, isInactive: true}} key={'mutedIcon'} />);
		}

		if (sensor.device_state === 'CONFIGURING') {
			headerLeft.push(<LoadingOutlined id={'btn' + sensor.device_name + 'Pairing'} style={{color: '#3d9bfe'}} key={'pairingIcon'} />);
		} else if (sensor.device_state === 'PREPARED' || sensor.device_state === 'CONFIG PENDING') {
			headerLeft.push(
				<CaretRightFilled
					style={{color: '#50af47'}}
					id={'btn' + sensor.device_name + 'Start'}
					onClick={props.sensorConfigurationLastStep}
					key={'startButton'}
				/>
			);
		}

		if (alarmIconSettings) {
			headerLeft.push(
				<Link to={iconLink} title={iconLinkTitle} key={'alarmIconLink'}>
					<WarningIcon event={alarmIconSettings} key={'alarmIcon'} />
				</Link>
			);
		}
	}

	if (warningIconSettings) {
		headerLeft.push(
			<Link to={iconLink} title={iconLinkTitle} key={'warningIconLink'}>
				<WarningIcon event={warningIconSettings} key={'warningIcon'} />
			</Link>
		);
	}

	if (sensor.signal_strength) {
		headerRight.push(<SignalStrengthComponent signalStrength={signalStrength} key={'signalStrengthComponent'} />);
	}
	headerRight.push(<SensorDropdown sensor={sensor} key={'sensorDropdown'} buttonSize={'small'} />);

	const gutter: number = 8;

	const leftRightColSpan: number = Math.max(headerLeft.length, headerRight.length) * 3;
	const titleColSpan: number = 24 - 2 * leftRightColSpan - gutter / 2;

	return (
		<div style={{margin: '.5em'}}>
			<Row justify={'space-between'} wrap={false}>
				<Col span={leftRightColSpan}>
					<Row gutter={gutter} wrap={false} justify={'start'}>
						{headerLeft.map((child, idx) => (
							<Col key={`headerLeft${idx}`}>{child}</Col>
						))}
					</Row>
				</Col>
				<Col
					style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textAlign: 'center'}}
					title={getTitle()}
					span={titleColSpan}
				>
					{getTitle()}
				</Col>
				<Col span={leftRightColSpan}>
					<Row gutter={gutter} wrap={false} justify={'end'}>
						{headerRight.map((child, idx) => (
							<Col key={`headerRight${idx}`}>{child}</Col>
						))}
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default SensorHeader;
