import React from 'react';
import SensorHeader from './SensorHeader/SensorHeader';
import SensorBodyComponent from './SensorBodyComponent';
import SensorFooter from './SensorFooter';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import ReactTooltip from 'react-tooltip';
import {SensorComponentProps} from './SensorComponentProps';

const SensorComponent = (props: SensorComponentProps) => {
	const sensor = props.sensor;

	const getBackgroundColor = (): string => {
		let defaultColor = '#FFFFFF';
		let alarmColor = '#f8baba';
		let warnColor = '#ffea8c';

		if (sensor.is_retired) {
			return defaultColor;
		}

		if (sensor.has_alarm) {
			return alarmColor;
		}

		if (sensor.has_warning) {
			return warnColor;
		}

		return defaultColor;
	};

	const userOffset = DateTimeUtils.getCurrentUserTZOffset();
	const backgroundColor = getBackgroundColor();

	return (
		<>
			{
				<div
					id={'sensorCard' + sensor.device_name}
					className={'card card-default dashboard-sensor-card'}
					style={{backgroundColor: backgroundColor, margin: '0'}}
				>
					<SensorHeader
						fillingColor={backgroundColor}
						sensor={sensor}
						sensorConfigurationLastStep={props.sensorConfigurationLastStep}
					/>
					<span data-tip="true" data-for={'sensor_' + sensor.id}>
						<SensorBodyComponent
							optimizeFor={props.optimizeFor}
							isRetired={sensor.is_retired}
							fillingColor={backgroundColor}
							analyseSensor={props.analyseSensor}
							sensor={sensor}
						/>
						{sensor.last_measurement_status ? (
							<ReactTooltip id={'sensor_' + sensor.id} className="dashboardSensorErrorCodeToolTip">
								<span>SENSOR ERROR: {sensor.last_measurement_error}</span>
							</ReactTooltip>
						) : null}
					</span>
					<SensorFooter
						fillingColor={backgroundColor}
						sensor={sensor}
						moduleShortNameBackgroundColor={sensor.sensor_background_color}
						userOffset={userOffset}
					/>
				</div>
			}
		</>
	);
};

export default SensorComponent;
