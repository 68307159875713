import {DeviceUtils} from './DeviceUtils';
import {EcologProRadioType} from '../constants/DeviceType';
import {SensorService} from '../services';
import {Sensor} from '../../components/Common/Types/Sensor';
import FindMaxChannelCount from './FindMaxChannelCount';

export const groupModules = (sensors: Sensor[]): Map<string, number[]> => {
	let initialValue = new Map<string, number[]>();
	if (!sensors || typeof sensors !== typeof ([] as {id: number; serialNumber: string; retired: boolean}[])) {
		return initialValue;
	}

	const groups = sensors.reduce((moduleGroups, sensor) => {
		const groupingKey = `${sensor.SerialNumber}${sensor.IsRetired ? '_retired' : ''}`;
		if (!moduleGroups.has(groupingKey)) {
			moduleGroups.set(groupingKey, []);
		}
		moduleGroups.get(groupingKey).push(sensor.Id);
		return moduleGroups;
	}, initialValue);

	const retiredSplitGroups = new Map<string, number[]>();
	groups.forEach((sensorIds, key) => {
		const maxChannelCount = FindMaxChannelCount(key);
		const sortedSensorIds = [...sensorIds].sort((a, b) => a - b);
		for (let i = 0; i < sortedSensorIds.length; i += maxChannelCount) {
			const chunk = sortedSensorIds.slice(i, i + maxChannelCount);
			retiredSplitGroups.set(`${key}_${i / maxChannelCount}`, chunk);
		}
	});

	return retiredSplitGroups;
};

export async function getAdditionallyAffectedSensorsAsync(sensor: Sensor): Promise<Sensor[]> {
	if (DeviceUtils.GetDeviceType(sensor.SerialNumber) === EcologProRadioType.TwoPTR) {
		return [];
	}

	try {
		const sensors = await SensorService.GetModuleSensors(sensor.SerialNumber);
		const additionallyAffectedSensors = sensors.filter(s => s.id !== sensor.Id);

		return additionallyAffectedSensors.map(s => new Sensor(s));
	} catch (e) {
		return await Promise.reject(e);
	}
}
