import React from 'react';
import {toast} from 'react-toastify';
import {CheckCircleOutlined, CloseCircleOutlined, WarningOutlined} from '@ant-design/icons';

function renderIcon(type) {
	switch (type) {
		case 'success':
			return <CheckCircleOutlined className={'toast-icon'} />;
		case 'warning':
			return <WarningOutlined className={'toast-icon'} />;
		default:
			return <CloseCircleOutlined className={'toast-icon'} />;
	}
}

const ToastContent = ({title, msg, type, description}) => {
	return (
		<div className={'toast-content'}>
			<div className="toast-content-pos">{renderIcon(type)}</div>
			<div className="toast-content-pos">
				<h1>{title}</h1>
				<p>{msg}</p>
				{description && description.length > 0 ? <p className={'error-message'}>Error: {description}</p> : null}
			</div>
		</div>
	);
};

function warning(title, msg, {...options} = {}) {
	return toast.warn(<ToastContent title={title} msg={msg} type="warning" description={null} />, {
		className: 'toast-warning-wrapper',
		...options,
	});
}

function success(title, msg, {...options} = {}) {
	return toast.success(<ToastContent title={title} msg={msg} type="success" description={null} />, {
		className: 'toast-success-wrapper',
		...options,
	});
}

function trErr(err, trFun = undefined) {
	return err && err.data ? (err.data.message ? (trFun ? trFun(err.data.message) : err.data.message) : err.data) : err;
}

// print a toast error message
// or alternatively a string
// in some cases there is just a string, in others there is the object
// if error is null or empty string, the error details of the toast omitted
// its a unified error handling by a compound error object (such as aspnetcore gives)
// or error as string as coming from a proxy
function error(title, msg, err = undefined) {
	return toast.error(<ToastContent title={title} msg={msg} type="error" description={trErr(err)} />, {
		autoClose: 6000,
		className: 'toast-error-wrapper',
	});
}

//has no description
//msg is in msg
//err is translated, if i18n added
function toastErr(msgTitle, i18n = undefined) {
	return toast.error(<ToastContent type="error" title={i18n ? i18n.t(msgTitle) : msgTitle} msg={null} description={null} />, {
		autoClose: 6000,
		className: 'toast-error-wrapper',
	});
}

const Messages = {
	success,
	warning,
	error,
	toastErr,
};

export default Messages;
