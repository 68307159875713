import {SideBarDef} from 'ag-grid-community';

export const GridSideBar: SideBarDef = {
	toolPanels: [
		{
			id: 'filters',
			labelDefault: 'Filters',
			labelKey: 'filters',
			iconKey: 'filter',
			toolPanel: 'agFiltersToolPanel',
			minWidth: 180,
			maxWidth: 400,
			width: 250,
		},
	],
	position: 'right',
};
