import React, {useEffect, useState} from 'react';
import {Form, FormGroup, Input, Label} from 'reactstrap';
import Alert from '../Shared/Components/Alert';
import Modal from '../Shared/Components/Modal';
import {CONSTANTS} from '../Shared/Constants/Constants';
import {Trans} from 'react-i18next';
import CalibrationService from '../Services/Calibrations/CalibrationService';
import {Sensor} from '../../components/Common/Types/Sensor';

function getAdditionallyAffectedSensorElements(affected_sensors: Sensor[]) {
	if (!affected_sensors) {
		return <></>;
	}

	return affected_sensors.map(sensor => (
		<div key={sensor.Id + '_div'}>
			<Label key={sensor.Id + '_label'} style={{marginTop: '10px', marginBottom: '0px'}}>
				Additional Sensor
			</Label>
			<Input key={sensor.Id + '_input'} type="text" value={sensor?.Name} disabled />
		</div>
	));
}

const StartCalibrationModal = ({isLoading, isVisible, onChangeModalVisibility, sensor, addidionallyAffectedSensor, startCallback}) => {
	const [visible, setVisible] = useState(isVisible);
	const [loading, setLoading] = useState(isLoading);
	const [formError, setFormError] = useState(false);
	const [submitError, setSubmitError] = useState(false);
	const [description, setDescription] = useState(CONSTANTS.EMPTY);
	const [duration_h, setDuration_h] = useState(18);
	const [duration_m, setDuration_m] = useState(0);
	const [startCalibrationRun, setStartCalibrationRun] = useState(false);

	const calibrationService: CalibrationService = new CalibrationService();

	// Visibility
	useEffect(() => {
		setVisible(isVisible);
	}, [isVisible]);

	// OkButton Loading
	useEffect(() => {
		setLoading(isLoading);
	}, [isLoading]);

	// Acknowledge
	useEffect(() => {
		if (!startCalibrationRun) {
			return;
		}

		const hourSeconds = duration_h ? duration_h * 60 * 60 : 0;
		const minuteSeconds = duration_m ? duration_m * 60 : 0;

		const totalDuration = hourSeconds + minuteSeconds;

		if (totalDuration <= 0 || totalDuration > 18 * 60 * 60) {
			setFormError(true);
			return;
		}

		const startCalibration = async () => {
			setLoading(true);
			const startSucceeded = await calibrationService.tryStartCalibration(sensor.Id, description, totalDuration);

			setLoading(false);

			if (!startSucceeded) {
				setSubmitError(true);
				await new Promise(resolve => setTimeout(resolve, 10000));
				setSubmitError(false);
				return;
			}
			startCallback();
		};
		startCalibration();
	}, [startCalibrationRun]);

	const handleModalCancel = () => {
		onChangeModalVisibility(false);
	};

	const handleAfterClose = () => {
		setSubmitError(false);
		setFormError(false);
		setStartCalibrationRun(false);
		setDescription(CONSTANTS.EMPTY);
		setDuration_h(18);
		setDuration_m(0);
	};

	return (
		<Modal
			title={<Trans i18nKey={'sensor.startCalibration'} />}
			open={visible}
			confirmLoading={loading}
			onCancel={handleModalCancel}
			afterClose={handleAfterClose}
			onOk={() => {
				setStartCalibrationRun(true);
			}}
		>
			<div>
				<Form id="startCalibration" name="startCalibration">
					<fieldset>
						<Label for="moduleSerialNumber" style={{marginBottom: '0px'}}>
							Module
						</Label>
						<Input type="text" id="moduleSerialNumber" value={sensor?.SerialNumber} disabled />
						<Label for="sensorName" style={{marginTop: '10px', marginBottom: '0px'}}>
							Sensor
						</Label>
						<Input type="text" id="sensorName" value={sensor?.Name} disabled />
						{getAdditionallyAffectedSensorElements(addidionallyAffectedSensor)}
					</fieldset>
					<fieldset>
						<Label for="description" style={{marginBottom: '0px'}}>
							<Trans i18nKey={'calibrations.description'} />
						</Label>
						<Input
							type="textarea"
							id="description"
							value={description}
							rows={3}
							maxLength={500}
							style={{resize: 'none'}}
							onChange={e => {
								setDescription(e.target.value);
							}}
						/>
						<Label for="duration_h" style={{marginTop: '10px', marginBottom: '0px'}}>
							<Trans i18nKey={'sensor.maxCalibrationDuration'} />
						</Label>
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '50%'}}>
							<Label for="duration_h" hidden>
								Hour
							</Label>
							<Input
								className={`${formError ? 'is-invalid form-control' : ''}`}
								type="number"
								style={{marginRight: '10px'}}
								id="duration_h"
								value={duration_h}
								min="0"
								max="18"
								placeholder="Hour"
								onChange={e => {
									setDuration_h(e.target.value);
									setFormError(false);
									setStartCalibrationRun(false);
									setSubmitError(false);
								}}
							/>
							<a>:</a>
							<Label for="duration_m" hidden>
								Minute
							</Label>
							<Input
								className={`${formError ? 'is-invalid form-control' : ''}`}
								type="number"
								style={{marginLeft: '10px'}}
								id="duration_m"
								value={duration_m}
								min="0"
								max="59"
								placeholder="Minute"
								onChange={e => {
									setDuration_m(e.target.value);
									setFormError(false);
									setStartCalibrationRun(false);
									setSubmitError(false);
								}}
							/>
						</div>
					</fieldset>
					{submitError && (
						<Alert message="Warning" description={'Something went wrong while starting the calibration!'} type="error" />
					)}
				</Form>
			</div>
		</Modal>
	);
};

export default StartCalibrationModal;
