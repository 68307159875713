import {CellClassRules} from 'ag-grid-enterprise';
import {AlarmState} from '../../common/constants';
import {SensorInfo} from '../Services/Sensors/SensorInfo';

const isGroupingHeader = (params: any) => params?.node.group && params.node.rowGroupColumn !== params.column;

export const LimitAlarmStateCellClassRules: CellClassRules<SensorInfo> = {
	'state-ok': params => !isGroupingHeader(params) && params.data.alarm_state === AlarmState.Ok,
	'state-alarm': params => !isGroupingHeader(params) && params.data.alarm_state === AlarmState.Alarm,
	'state-alarm-deactivated': params => !isGroupingHeader(params) && params.data.alarm_state === AlarmState.Deactivated,
	blurred: params => !isGroupingHeader(params) && params.data?.is_expired,
};
