import io from 'socket.io-client';
import pubsub from 'pubsub-js';
import Auth from '../Authentication/Authentication';
import AppSettings from '../Settings/AppSettings';
import {DEVICE_EVENT} from '../../Shared/Constants/Events';
import {MeasurementNew} from '../../Common/Types/WebSocket/MeasurementNew';

const url = AppSettings.getBaseUrl();
let socket = undefined;
let bridgeConfigurationSocket = undefined;

const WebSocket = {
	init() {
		if (socket) {
			return;
		}

		socket = io(url, {transports: ['websocket']});
		socket.on('connect', function () {
			socket.emit('authenticate', {token: Auth.getToken()});
		});

		const defaultHandler = (topic: string, data: any) => {
			if (data.sensors_id) {
				topic += '.' + data.sensors_id;
			}
			pubsub.publish(topic, data);
		};

		socket.on(DEVICE_EVENT.ALL_DEVIATION_ACKNOWLEDGED, (data: any) => defaultHandler(DEVICE_EVENT.ALL_DEVIATION_ACKNOWLEDGED, data));
		socket.on(DEVICE_EVENT.AUDIT_TRAIL_NEW, (data: any) => defaultHandler(DEVICE_EVENT.AUDIT_TRAIL_NEW, data));
		socket.on(DEVICE_EVENT.BRIDGE_CONFIGURATION_DONE, (data: any) => defaultHandler(DEVICE_EVENT.BRIDGE_CONFIGURATION_DONE, data));
		socket.on(DEVICE_EVENT.BRIDGE_CONFIGURATION_SUCCESSFUL, (data: any) =>
			defaultHandler(DEVICE_EVENT.BRIDGE_CONFIGURATION_SUCCESSFUL, data)
		);
		socket.on(DEVICE_EVENT.DEVIATION_ACKNOWLEDGED, (data: any) => defaultHandler(DEVICE_EVENT.DEVIATION_ACKNOWLEDGED, data));
		socket.on(DEVICE_EVENT.DEVIATION_NO_LONGER_ACTIVE, (data: any) => defaultHandler(DEVICE_EVENT.DEVIATION_NO_LONGER_ACTIVE, data));
		socket.on(DEVICE_EVENT.DEVIATION_OCCURRED, (data: any) => defaultHandler(DEVICE_EVENT.DEVIATION_OCCURRED, data));
		socket.on(DEVICE_EVENT.MEASUREMENT_NEW, (data: any) => defaultHandler(DEVICE_EVENT.MEASUREMENT_NEW, new MeasurementNew(data)));
		socket.on(DEVICE_EVENT.RUN_STATUS_CHANGED, (data: any) => defaultHandler(DEVICE_EVENT.RUN_STATUS_CHANGED, data));
		socket.on(DEVICE_EVENT.SENSOR_NEW, (data: any) => defaultHandler(DEVICE_EVENT.SENSOR_NEW, data));
		socket.on(DEVICE_EVENT.SENSOR_DELETED, (data: any) => defaultHandler(DEVICE_EVENT.SENSOR_DELETED, data));
		socket.on(DEVICE_EVENT.SENSOR_STATUS_CHANGED, (data: any) => defaultHandler(DEVICE_EVENT.SENSOR_STATUS_CHANGED, data));
		socket.on(DEVICE_EVENT.UPDATE_DASHBOARD_LIBERO_G, (data: any) => defaultHandler(DEVICE_EVENT.UPDATE_DASHBOARD_LIBERO_G, data));
		socket.on(DEVICE_EVENT.SIGNAL_STRENGTH_NEW, (data: any) => defaultHandler(DEVICE_EVENT.SIGNAL_STRENGTH_NEW, data));

		/** @deprecated legacy topic */
		socket.on(DEVICE_EVENT.MEASUREMENT_NEW, function (data) {
			pubsub.publish('newMeasurement/' + data.sensors_id, data);
		});

		/** @deprecated legacy topic */
		socket.on(DEVICE_EVENT.SENSOR_STATUS_CHANGED, function (data) {
			pubsub.publish(DEVICE_EVENT.SENSOR_STATUS_CHANGED + '/' + data.sensors_id, data);
		});

		// This topic does not exist ?
		socket.on('geo_data_new', function (data) {
			pubsub.publish('geo_data_new/' + data.sensors_id, data);
		});
	},

	deauthenticate() {
		const token = Auth.getToken();
		socket.emit('deauthenticate', {token: token});
		socket = undefined;
	},

	send() {
		if (socket !== undefined) {
			socket.emit('message', {data: 'somedata'});
		}
	},

	sendNotification(text) {
		if (socket !== undefined) {
			socket.emit('message', text);
		}
	},

	sendAlarm(data) {
		if (socket !== undefined) {
			socket.emit('message', data);
		}
	},

	connectToBridgeConfigurationChannel(token) {
		bridgeConfigurationSocket = io(url, {transports: ['websocket']});
		bridgeConfigurationSocket.on('connect', function () {
			bridgeConfigurationSocket.emit('bridgeConfiguration', {token: token});
		});

		bridgeConfigurationSocket.on('bridge_configuration', function (data) {
			pubsub.publish('bridge_configuration', data);
		});
	},
};

export default WebSocket;
