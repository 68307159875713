import {ColumnApi, GridApi} from 'ag-grid-enterprise';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {AgGridWrapper} from '../Shared/Components';
import {AgGridReact} from 'ag-grid-react';
import {GridSideBar} from './SideBarDefinitions';
import pubsub from 'pubsub-js';
import {DefaultCellFormatter} from './ValueFormatters';
import {getColumnDefinitions} from './ColumnDefinitions';
import {SettingsSaveComponent} from './SettingsSaveComponent';
import {DefaultValueGetter} from './ValueGetters';
import {CellDoubleClickedEvent, GridReadyEvent, IServerSideDatasource, IServerSideGetRowsParams} from 'ag-grid-community';
import {useHistory} from 'react-router-dom';
import Shared from '../Shared/Shared';
import {MultiContext} from '../Infrastructure/Authorization/Context/MultiContext';
import {GetDataGridSetting} from '../../common/services/WebserverServicePro/UserSettingsService';
import {AuthContext} from '../../common/context';
import {SensorService} from '../../common/services';
import {SensorInfo} from 'components/Services/Sensors/SensorInfo';
import CountStatusBar from './CountStatusBar';
import {SensorActions} from '../../common/helpers/SensorActions';
import {MeasurementNew} from '../../components/Common/Types/WebSocket/MeasurementNew';
import {
	CalibrationStatusChanged,
	DEVICE_EVENT,
	RunStatusChanged,
	SensorAlarmingEnabledChangedData,
	UI_EVENT,
} from '../../components/Shared/Constants/Events';
import {ToOutUnit} from '../../common/util/MeasurementValueUtils';
import {Sensor} from '../../components/Common/Types/Sensor';

export interface GridApiRef {
	api: GridApi<SensorInfo>;
	columnApi: ColumnApi;
}

export function SensorDataGrid() {
	const gridRef = useRef();
	const history = useHistory();

	const accessContext = useContext(MultiContext);
	const authContext = useContext(AuthContext);

	const [columnDefs] = useState(getColumnDefinitions({tiltEnabled: false}));

	const cacheBlockSize = 50;
	let totalRows: number = undefined;

	useEffect(() => {
		Shared.saveDevicesViewSetting(accessContext, '/datagrid');
	}, []);

	function getGridApi(): GridApiRef {
		if (!gridRef?.current) {
			return {api: undefined, columnApi: undefined};
		}
		const {api, columnApi} = gridRef.current as GridApiRef;
		if (api == null || columnApi == null) {
			return {api: undefined, columnApi: undefined};
		}

		return {api: api, columnApi: columnApi};
	}

	async function applyStoredSettings() {
		const dataGridSettings = await GetDataGridSetting();
		const {api, columnApi} = getGridApi();
		if (!api || !columnApi) {
			return;
		}

		api.setColumnDefs(getColumnDefinitions({tiltEnabled: authContext.Organization?.Config?.global?.tiltEnabled}));
		api.setFilterModel(dataGridSettings.filterSettings);
		columnApi.applyColumnState({state: dataGridSettings.columnSettings, applyOrder: true});
	}

	async function refreshViewData(sensor_id?: number) {
		const {api} = getGridApi();
		if (api) {
			if (!sensor_id) api.refreshServerSide();
			else {
				const sensorInfo = await SensorService.GetSensorInfo(sensor_id);
				const sensorNode = api.getRowNode(sensor_id.toString());

				if (sensorInfo && sensorNode) {
					sensorNode.updateData(sensorInfo);
				}
			}
		}
	}

	function handleNewMeasurement(data: MeasurementNew) {
		const {api} = getGridApi();
		if (api) {
			const sensor = api.getRowNode(data.SensorId.toString());
			if (sensor) {
				const updatedSensor = sensor.data;
				updatedSensor.last_measurement_tstamp = data.Timestamp.toUTCString();
				updatedSensor.last_measurement_value = ToOutUnit(data.Value, sensor.data.out_units_id);
				updatedSensor.last_measurement_tilt_angle = data.TiltAngle;

				sensor.updateData(updatedSensor);
			}
		}
	}

	function setupDataChangedSubscription() {
		pubsub.subscribe(DEVICE_EVENT.MEASUREMENT_NEW, (_msg: string, data: MeasurementNew) => handleNewMeasurement(data));
		pubsub.subscribe(DEVICE_EVENT.SENSOR_NEW, (_msg: string, data) => refreshViewData());
		pubsub.subscribe(DEVICE_EVENT.SENSOR_DELETED, (_msg: string, data) => refreshViewData());
		pubsub.subscribe(DEVICE_EVENT.RUN_STATUS_CHANGED, (_msg: string, data: RunStatusChanged) => refreshViewData(data.sensor_id));
		pubsub.subscribe(UI_EVENT.SENSOR_ALARMING_ENABLED_CHANGED, (_msg: string, data: SensorAlarmingEnabledChangedData) =>
			refreshViewData(data.SensorId)
		);
		pubsub.subscribe(DEVICE_EVENT.UPDATE_DASHBOARD_LIBERO_G, (_msg: string, data: {sensors_id: number}) =>
			refreshViewData(data.sensors_id)
		);
		pubsub.subscribe(UI_EVENT.CALIBRATION_STATUS_CHANGED, (_msg: string, data: CalibrationStatusChanged) =>
			refreshViewData(data.sensorId)
		);
		pubsub.subscribe(DEVICE_EVENT.SENSOR_STATUS_CHANGED, (_msg: string, data: {sensors_id: number}) =>
			refreshViewData(data.sensors_id)
		);
	}

	function onCellDoubleClicked(event: CellDoubleClickedEvent<SensorInfo>) {
		const sensor = event?.data;
		if (sensor && event.colDef?.colId !== 'Menu') {
			SensorActions.Analyse(new Sensor(sensor), history);
		}
	}

	async function getRowsFromServer(params: IServerSideGetRowsParams<SensorInfo>) {
		const {api} = getGridApi();
		if (totalRows && params.request.startRow >= totalRows && !params.request.filterModel && !params.request.groupKeys.length) {
			return;
		}

		let sensors = [];
		try {
			const results = await SensorService.GetSensorsFromGridInfo(params.request);
			sensors = results.sensors;
			totalRows = results.total;
		} catch (e) {
			if (e.data.code === 'PGRST103') {
				api.ensureIndexVisible(0, null);
				params.request.startRow = 0;
				params.request.endRow = cacheBlockSize;
			} else {
				console.error(e.data.code);
			}
		}

		if (params.request.groupKeys.length < params.request.rowGroupCols.length) {
			const groupColIds = params.request.rowGroupCols.map(col => col.id);
			sensors = getGroupRows(sensors, groupColIds);
			totalRows = sensors.length;
		}

		params.success({
			rowData: sensors,
			rowCount: totalRows * 1,
		});
	}

	function getGroupRows(sensors: any[], groupColIds: string[]): SensorInfo[] {
		return sensors.filter((sensor, index, self) => groupColIds.every(id => self.findIndex(s => s[id] === sensor[id]) === index));
	}

	function getServerSideDatasource(): IServerSideDatasource {
		return {
			getRows: async params => getRowsFromServer(params),
		};
	}

	async function onGridReady(_params: GridReadyEvent) {
		const {api, columnApi} = getGridApi();
		if (!api || !columnApi) {
			return;
		}

		api.setServerSideDatasource(getServerSideDatasource());
		api.setColumnDefs(getColumnDefinitions({tiltEnabled: authContext.Organization?.Config?.global?.tiltEnabled}));
		await applyStoredSettings();

		setupDataChangedSubscription();
	}

	return (
		<AgGridWrapper>
			<AgGridReact
				getRowId={params => {
					const groupColumns = params.columnApi.getRowGroupColumns();
					if (groupColumns.length > params.level) {
						return params.data[groupColumns[params.level].getColId()];
					}
					return params.data.id;
				}}
				cacheBlockSize={cacheBlockSize}
				onGridReady={onGridReady}
				rowModelType={'serverSide'}
				columnDefs={columnDefs}
				ref={gridRef}
				serverSideFilterOnServer={true}
				serverSideSortOnServer={true}
				serverSideInfiniteScroll={true}
				rowClassRules={{blurred: params => params.data?.is_expired}}
				rowGroupPanelShow={'always'}
				sideBar={GridSideBar}
				statusBar={{
					statusPanels: [
						{statusPanel: CountStatusBar, align: 'left'},
						{statusPanel: SettingsSaveComponent, align: 'right'},
					],
				}}
				columnHoverHighlight={true}
				defaultColDef={{
					valueGetter: DefaultValueGetter,
					valueFormatter: DefaultCellFormatter,
					sortable: true,
					resizable: true,
					enableRowGroup: true,
					filter: 'agSetColumnFilter',
				}}
				onCellDoubleClicked={onCellDoubleClicked}
				enableCellChangeFlash={true}
			/>
		</AgGridWrapper>
	);
}
