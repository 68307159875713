import React, {useState} from 'react';
import SensorBodyUnit from './SensorBodyUnit';

import GetSensorBodyTemperaturePath from './SensorBodyTemperature';
import GetSensorBodyHumidityPath from './SensorBodyHumidity';
import {SensorType} from '../../Common/Types/SensorType';
import {SensorInfo} from '../../Services/Sensors/SensorInfo';
import DateTimeUtils from '../../../components/Infrastructure/DateTime/DateTimeUtils';

export enum OptimizeFor {
	Speed,
	Quality,
}

function getViewBoxStyle(optimizeFor: OptimizeFor): React.CSSProperties {
	return {
		marginLeft: '12px',
		shapeRendering: optimizeFor === OptimizeFor.Quality ? 'geometricPrecision' : 'optimizeSpeed',
		textRendering: optimizeFor === OptimizeFor.Quality ? 'geometricPrecision' : 'optimizeSpeed',
		imageRendering: optimizeFor === OptimizeFor.Quality ? 'crisp-edges' : 'pixelated',
		fillRule: 'evenodd',
		clipRule: 'evenodd',
		cursor: 'pointer',
	};
}

function computeFill(sensor: SensorInfo) {
	const y0 = 6800; // Y value at lower limit
	const yBase = 4400; // Px between lower limit and upper limit
	const h0 = 15000;
	const minY = 8120; // the lowest possible
	const yMiddle = 4600; // for sensors without limit alarm
	const hMiddle = 10000; // for sensors without limit alarm

	if (sensor.last_measurement_value == null) {
		return {
			y: h0,
			h: 0,
			alarm: false,
		};
	}
	if (sensor.l1 == null || sensor.h1 == null) {
		return {
			y: yMiddle,
			h: hMiddle,
			alarm: false,
		};
	}

	const max = sensor.h1;
	const min = sensor.l1;
	const value = sensor.last_measurement_value;

	const alarm = value < min || value > max;
	const percentage = (value - min) * (1 / (max - min));

	let y = y0 - yBase * percentage;

	if (y > minY) {
		y = minY;
	}

	let h = h0 - y;

	return {
		y: y,
		h: h,
		alarm: alarm,
	};
}

interface SensorBodyGraphicProps {
	onClick: any;
	optimizeFor: OptimizeFor;
	sensor: SensorInfo;
	sensorAlarmDeactivatedColor: string;
	sensorColors: any;
	sensorValueColor: string;
	sensorFillingColor: string;
	sensorStrokeColor: string;
}

const fontFamily = 'Verdana, sans-serif';

export const SensorBodyGraphic = (props: SensorBodyGraphicProps) => {
	const setFontColor = muted => {
		return muted ? props.sensorColors.inactive : props.sensorColors.active;
	};
	const [sensor] = useState(props.sensor);

	const [alarmDeactivatedColor] = useState(props.sensorAlarmDeactivatedColor);
	const [strokeColor] = useState(props.sensorStrokeColor);
	const [valueColor] = useState(props.sensorValueColor);
	const [colors] = useState(props.sensorColors);

	let getPathFunction;
	switch (sensor.sensor_type) {
		case SensorType.Temperature: {
			getPathFunction = GetSensorBodyTemperaturePath;
			break;
		}
		case SensorType.Humidity: {
			getPathFunction = GetSensorBodyHumidityPath;
			break;
		}
	}

	let res = computeFill(sensor);
	const height = res.h;
	const y = res.y;

	let lastMeasurementTime = '';
	let lastMeasurementDate = '';

	if (sensor.last_measurement_tstamp) {
		lastMeasurementTime = DateTimeUtils.setDateTimeWithOffset_date_dep_time(new Date(sensor.last_measurement_tstamp));
		lastMeasurementDate = DateTimeUtils.setDateTimeWithOffset_date_dep_date(new Date(sensor.last_measurement_tstamp));
	}

	const fontColor = setFontColor(sensor.is_muted);

	const path = getPathFunction({
		muted: sensor.is_muted,
		isRetired: sensor.is_retired,
		alarmDeactivatedColor,
		hasLimits: sensor.has_limits,
		lastMeasurementOutsideLimits:
			sensor.has_limits &&
			sensor.last_measurement_value &&
			(sensor.last_measurement_value > sensor.h1 || sensor.last_measurement_value < sensor.l1),
		fillingColor: props.sensorFillingColor,
		strokeColor,
		y,
		height,
		colors,
	});

	const hasValue = (value: number) => value || value == 0;
	const formattedValue = (sensor: SensorInfo) => {
		const precision = 1;
		if (sensor.last_measurement_status) {
			return '--';
		}
		if (sensor.last_measurement_value == null) {
			return 'no value';
		}
		if (hasValue(sensor.last_measurement_value)) {
			return sensor.last_measurement_value.toFixed(precision);
		}
		return '--';
	};

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			version="1.1"
			onClick={props.onClick}
			viewBox="0 0 11680 9647"
			style={getViewBoxStyle(props.optimizeFor)}
		>
			{path}
			{sensor.is_retired ? null : (
				<g transform="translate(0, 0)">
					{hasValue(sensor.h1) && (
						<text
							id="UpperLimitText"
							x="0"
							y="2200"
							fontSize="700"
							fontFamily={fontFamily}
							textRendering="geometricPrecision"
							fill={fontColor}
						>
							{sensor.h1} <SensorBodyUnit unit={sensor.out_units_id} />
						</text>
					)}

					{hasValue(sensor.h1) && <polygon id="UpperLimitLine" fill="#b1b3b3" points="919,2363 7674,2363 7674,2459 919,2459 " />}

					{hasValue(sensor.l1) && (
						<text
							id="LowerLimitText"
							x="0"
							y="6550"
							fontSize="700"
							fontFamily={fontFamily}
							textRendering="geometricPrecision"
							fill={fontColor}
						>
							{sensor.l1} <SensorBodyUnit unit={sensor.out_units_id} />
						</text>
					)}

					{hasValue(sensor.l1) && <polygon id="LowerLimitLine" fill="#b1b3b3" points="919,6705 7674,6705 7674,6802 919,6802 " />}

					<text
						id="sensorValue"
						fontSize="800"
						fontWeight="bold"
						fontFamily={fontFamily}
						textRendering="geometricPrecision"
						y="4500"
						x="7800"
						fill={valueColor}
					>
						{formattedValue(sensor)}
						{sensor.last_measurement_value != null && <SensorBodyUnit unit={sensor.out_units_id} />}
					</text>

					<text
						id="sensorValueLastMeasurementDate"
						fontSize="600"
						fontWeight="regular"
						fontFamily={fontFamily}
						textRendering="geometricPrecision"
						y="5400"
						x="7800"
						fill={valueColor}
					>
						{lastMeasurementDate}
					</text>

					<text
						id="sensorValueLastMeasurementTime"
						fontSize="600"
						fontWeight="regular"
						fontFamily={fontFamily}
						textRendering="geometricPrecision"
						y="6100"
						x="7800"
						fill={valueColor}
					>
						{lastMeasurementTime}
					</text>
				</g>
			)}
		</svg>
	);
};
