import React from 'react';
import SensorDropdown from '../Dashboard/Sensor/SensorDropdown';
import {ICellRendererParams} from 'ag-grid-enterprise';
import {SensorInfo} from 'components/Services/Sensors/SensorInfo';

export function HamburgerMenu(props: ICellRendererParams<SensorInfo>) {
	if (props?.node.group && props.node.rowGroupColumn !== props.column) {
		return;
	}

	return props.data && <SensorDropdown sensor={props.data} />;
}
