import {ValueGetterParams} from 'ag-grid-enterprise';
import {CONSTANTS} from '../Shared/Constants/Constants';
import {DeviceUtils, GetUnitStringFromOutUnit} from '../../common/util';
import dayjs from 'dayjs';
import {TranslateGlobalString} from './ValueFormatters';
import {SensorInfo} from '../Services/Sensors/SensorInfo';

type GetterParams = ValueGetterParams<SensorInfo>;

export function DefaultValueGetter(params: GetterParams): string {
	const field = params.colDef.field;
	const value = params.data[field];

	if (!value || value === '' || (Array.isArray(value) && value.length === 0)) {
		return TranslateGlobalString(CONSTANTS.DEFAULT_EMPTY);
	}
	return value;
}

export function DeviceStateGetter(params: GetterParams): string {
	return params?.data?.device_state?.toUpperCase() ?? TranslateGlobalString(CONSTANTS.DEFAULT_EMPTY);
}

export function MeasurementDateGetter(params: GetterParams): string | dayjs.Dayjs {
	if (!params?.data?.last_measurement_tstamp) {
		return TranslateGlobalString(CONSTANTS.NO_DATA);
	}
	return params.data.last_measurement_tstamp;
}

export function MeasurementValueGetter(params: GetterParams): string | number {
	const value = params.data?.last_measurement_value;
	if (!value) {
		return TranslateGlobalString(CONSTANTS.NO_DATA);
	}

	const precision = 1;
	return `${Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision)} ${GetUnitStringFromOutUnit(
		params.data?.out_units_id
	)}`;
}

export function RunNumberValueGetter(params: GetterParams) {
	if (params?.data && !DeviceUtils.IsLiberoGx(params.data.serial_number)) {
		return CONSTANTS.NOT_AVAILABLE;
	}

	return params?.data?.run_count;
}

export function RunStartDateValueGetter(params: GetterParams): dayjs.Dayjs | string {
	if (params?.data && !DeviceUtils.IsLiberoGx(params.data.serial_number)) {
		return CONSTANTS.NOT_AVAILABLE;
	}

	return params?.data?.ts_start;
}

export function TiltAngleGetter(params: GetterParams): string | number {
	const tiltAngle = params.data?.last_measurement_tilt_angle;
	if (tiltAngle == null) {
		return CONSTANTS.NOT_AVAILABLE;
	}

	return `${Math.round(tiltAngle)}°`;
}
