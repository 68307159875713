import {SensorType} from './SensorType';
import {Measurement} from './Measurement';
import {RunInfoSummary} from '../../../common/types';
import {SensorInfo} from '../../Services/Sensors/SensorInfo';

export class Sensor {
	constructor(props: Partial<SensorInfo>) {
		this.Id = props.id;
		this.Name = props.device_name;
		this.SerialNumber = props.serial_number;
		this.ChannelNumber = props.channel_id;
		this.CustomerId = props.custom_id;
		this.State = props.state;
		this.OutUnitsId = props.out_units_id;
		this.ModulePrefix = props.module_prefix;
		this.ModuleShortName = props.module_short_name;
		this.IsRetired = props.is_retired;
		this.IsMuted = props.is_muted;
		this.HasLimits = props.has_limits;
		this.LiberoGStateDescription = props.libero_g_state_description;
		this.SignalStrength = props.signal_strength;
		this.SensorType = props.sensor_type;
		this.BackgroundColor = props.sensor_background_color;
		this.IsMultiRun = props.is_multi_run;
		this.MutingEnabled = props.muting_enabled;
		this.MultiRunStartRunEnabled = props.start_multirun_enabled;
		this.MultiRunLockedConfiguration = props.mr_locked_configuration;
		this.LoggingInterval = props.logging_interval;
		this.Status = props.last_measurement_status;
		this.Error = props.last_measurement_error;
		this.LatchActive = props.latch_active;
		this.LatchedStatus = props.latched_status;
		this.HasLimits = props.has_limits;
		this.LimitLower = props.l1;
		this.LimitUpper = props.h1;

		this.RunInfoSummary = new RunInfoSummary({
			org_id: undefined,
			sensors_id: props.id,
			run_count: props.run_count,
			ts_start: props.ts_start,
			ts_end: props.ts_end ? props.ts_end : null,
		});

		if (props.last_measurement_tstamp && props.last_measurement_value) {
			this.LastMeasurement = new Measurement(
				new Date(props.last_measurement_tstamp),
				props.last_measurement_value,
				props.last_measurement_tilt_angle
			);
		}

		this.AdditionalConfigMetadata = [];
		[
			props.metadata_1,
			props.metadata_2,
			props.metadata_3,
			props.metadata_4,
			props.metadata_5,
			props.metadata_6,
			props.metadata_7,
			props.metadata_8,
		].forEach(d => this.AdditionalConfigMetadata.push(d));
	}

	public AdditionalConfigMetadata: string[] | undefined = undefined;
	public BackgroundColor: string;
	public ChannelNumber: number;
	public CustomerId: string;
	public Id: number;
	public IsBlurred: boolean;
	public IsMultiRun: boolean;
	public IsMuted: boolean;
	public IsRetired: boolean;
	public HasLimits: boolean;
	public LastMeasurement: Measurement | undefined;
	public LatchActive: boolean;
	public LatchedStatus: boolean;
	public LiberoGStateDescription: string | null;
	public LimitLower: number;
	public LimitUpper: number;
	public LoggingInterval: number;
	public ModulePrefix: string;
	public ModuleShortName: string;
	public MultiRunLockedConfiguration: boolean;
	public MultiRunStartRunEnabled: boolean;
	public MutingEnabled: boolean;
	public Name: string;
	public OutUnitsId: number;
	public RunInfoSummary: RunInfoSummary = undefined;
	public SignalStrength: number | null;
	public SensorType: SensorType;
	public SerialNumber: string;
	public State: string;
	public Status: number = 0;
	public Error: string | null;
}
