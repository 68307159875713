import {DateTimeUtils} from '../Infrastructure/DateTime/DateTimeUtils';
import {ToOutUnit} from '../../common/util/MeasurementValueUtils';
import * as Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import React, {useEffect, useRef} from 'react';
import {PLOT_LINE_COLORS} from '../Shared/Constants/Chart';
import {GetUnitStringFromOutUnit} from '../../common/util';
import {IPredictionResult, ISensorInformation, ITemperatureData} from '@elproag/predict';
import {Sensor} from '../../components/Common/Types/Sensor';

NoDataToDisplay(Highcharts);

type PredictionResultGraphProps = {
	predictionResult: IPredictionResult;
	calculationId: string;
	sensorPredictiveInfo: ISensorInformation;
	sensor: Sensor;
};

export function PredictionResultGraph(props: PredictionResultGraphProps): React.JSX.Element {
	const assessment = props.sensorPredictiveInfo?.assessments?.at(0);
	const assessmentResult = assessment?.assessmentResults?.find(a => a.calculationId == props.calculationId);
	const chartRef = useRef(null);

	const zIndexPlotLines = 10;
	const zIndexPlotBands = 0;

	function getLaneEndTimestamp() {
		const earliestTimestamp = props.predictionResult?.ambientTemperatureMax?.at(0)?.timeStamp;
		if (!earliestTimestamp) {
			return undefined;
		}
		const laneDurationInMilliSec = 1000 * props.sensorPredictiveInfo.lane.durationInSeconds;

		return DateTimeUtils.stringToHighchartsTimestamp(earliestTimestamp) + laneDurationInMilliSec;
	}

	const laneEndTimestamp = getLaneEndTimestamp();
	const predictionStartTimestamp = getPredictionStartHighchartsTimestamp();
	const breachExpectedAtTimestamp = DateTimeUtils.stringToHighchartsTimestamp(assessmentResult?.breachExpectedAt);

	// DashStyleValue once highcharts is updated to a version with typings
	const predictionDashStyle:
		| 'Dash'
		| 'DashDot'
		| 'Dot'
		| 'LongDash'
		| 'LongDashDot'
		| 'LongDashDotDot'
		| 'ShortDash'
		| 'ShortDashDot'
		| 'ShortDashDotDot'
		| 'ShortDot'
		| 'Solid' = 'Dash';

	const commonSeriesOptions = {
		type: undefined,
		marker: {enabled: false},
	};

	const ambientSeriesOptions = {
		...commonSeriesOptions,
		// Split the ambient graph into two zones, with the predictions being dotted
		zoneAxis: 'x',
		zones: [{value: predictionStartTimestamp}, {dashStyle: predictionDashStyle}],
	};

	const payloadSeriesOptions = {
		...commonSeriesOptions,
		dashStyle: predictionDashStyle,
	};

	const chartOptions: Highcharts.Options = {
		credits: {enabled: false},
		time: {
			timezoneOffset: -DateTimeUtils.getCurrentUserTZOffset(),
		},
		title: {text: 'Prediction result'},
		xAxis: {
			type: 'datetime',
		},
		yAxis: {
			labels: {
				format: `{value} ${GetUnitStringFromOutUnit(props.sensor.OutUnitsId)}`,
			},
			title: {
				text: '',
			},
		},
		series: [
			{
				...ambientSeriesOptions,
				name: 'Ambient temperature min',
				data: temperatureDataToSeries(props.predictionResult.ambientTemperatureMin),
			},
			{
				...ambientSeriesOptions,
				name: 'Ambient temperature max',
				data: temperatureDataToSeries(props.predictionResult.ambientTemperatureMax),
			},
			{
				...payloadSeriesOptions,
				name: 'Payload temperature min',
				data: temperatureDataToSeries(props.predictionResult.payloadTemperatureMin),
			},
			{
				...payloadSeriesOptions,
				name: 'Payload temperature max',
				data: temperatureDataToSeries(props.predictionResult.payloadTemperatureMax),
			},
		],
	};

	const commonPlotBandOpts = {
		zIndex: zIndexPlotBands,
	};

	const plotBands = assessment
		? {
				lowerLimit: {
					...commonPlotBandOpts,
					to: ToOutUnit(assessment.assessmentRequest.lowerLimit, props.sensor.OutUnitsId),
					color: PLOT_LINE_COLORS.L1LIMIT,
					id: 'plotBandLowerLimit',
				},
				upperLimit: {
					...commonPlotBandOpts,
					from: ToOutUnit(assessment.assessmentRequest.upperLimit, props.sensor.OutUnitsId),
					color: PLOT_LINE_COLORS.H1LIMIT,
					id: 'plotBandUpperLimit',
				},
		  }
		: undefined;

	const commonPlotLineOpts = {
		color: 'black',
		dashStyle: 'solid',
		label: {
			textAlign: 'center',
			verticalAlign: 'middle',
		},
		width: 1,
		zIndex: zIndexPlotLines,
	};

	useEffect(() => {
		const chart = chartRef.current?.chart;
		drawPlotLineLaneEnd(chart);
	}, [laneEndTimestamp, chartRef]);

	useEffect(() => {
		const chart = chartRef.current?.chart;
		drawPlotLinePredictionStart(chart);
	}, [predictionStartTimestamp, chartRef]);

	function temperatureDataToSeries(temperatureData: ITemperatureData[]) {
		if (!Array.isArray(temperatureData)) {
			return [];
		}

		return temperatureData.map(t => [
			DateTimeUtils.stringToHighchartsTimestamp(t.timeStamp),
			ToOutUnit(t.value, props.sensor.OutUnitsId),
		]);
	}

	function getPredictionStartHighchartsTimestamp(): number {
		// Find the index where the ambient temperatures diverge
		let idx = props.predictionResult.ambientTemperatureMax.findIndex(
			(t, idx) => t.value !== props.predictionResult.ambientTemperatureMin[idx].value
		);
		// Reduce the index by one except for zero (immediate match) and negative one (no match)
		idx = idx > 0 ? idx - 1 : idx;

		const timestamp = props.predictionResult.ambientTemperatureMax.at(idx)?.timeStamp;
		if (!timestamp) {
			return undefined;
		}

		return DateTimeUtils.stringToHighchartsTimestamp(timestamp);
	}

	function drawPlotLineLaneEnd(chart) {
		if (!laneEndTimestamp || !chart) {
			return;
		}
		chart.xAxis[0].addPlotLine({
			...commonPlotLineOpts,
			label: {
				...commonPlotLineOpts.label,
				text: 'Lane end',
			},
			value: laneEndTimestamp,
		});
	}

	function drawPlotLinePredictionStart(chart) {
		if (!predictionStartTimestamp || !chart) {
			return;
		}

		chart.xAxis[0].addPlotLine({
			...commonPlotLineOpts,
			label: {
				...commonPlotLineOpts.label,
				text: 'Latest measurement',
			},
			value: predictionStartTimestamp,
		});
	}

	function drawPlotLineBreachExpectedAt(chart) {
		if (!breachExpectedAtTimestamp || !chart) {
			return;
		}

		chart.xAxis[0].addPlotLine({
			...commonPlotLineOpts,
			label: {
				...commonPlotLineOpts.label,
				text: 'Breach expected',
			},
			value: breachExpectedAtTimestamp,
		});
	}

	function drawLimitPlotBands(chart) {
		if (!plotBands) {
			return;
		}
		const extremes = chart.yAxis[0].getExtremes();
		if (extremes.min != undefined) {
			chart.yAxis[0].addPlotBand({
				...plotBands.lowerLimit,
				from: extremes.min,
			});
		}
		if (extremes.max != undefined) {
			chart.yAxis[0].addPlotBand({
				...plotBands.upperLimit,
				to: extremes.max,
			});
		}
	}

	function chartCallback(chart) {
		drawLimitPlotBands(chart);
		drawPlotLineLaneEnd(chart);
		drawPlotLinePredictionStart(chart);
		drawPlotLineBreachExpectedAt(chart);
	}

	return (
		<>
			<ReactHighcharts config={chartOptions} ref={chartRef} callback={chartCallback} />
		</>
	);
}
