import React, {Component} from 'react';
import {ContentWrapper, ViewWrapper} from '../../../../Layout';
import {Trans} from 'react-i18next';
import {getSensorSummarySettings} from '../../Helpers/SensorSettingsBuilder';
import SerialNumberInfo from '../../../../Shared/SerialNumberInfo';
import {MODULE_FAMILIES} from '../../../../Shared/Constants/Module';
import {MultiContext} from '../../../../Infrastructure/Authorization/Context/MultiContext';

// Services
import LegacySensorService from '../../SensorService';
import EditSensorService from '../../SensorService';
import IssueProfileService from '../../../../Services/IssueProfiles/IssueProfilesService';
import LicensingService from '../../../../Settings/Licenses/LicensingService';

// Forms
import SensorSummaryFormComponent from './SensorSummaryFormComponent.js';
import {RequestLogger} from '../../../../Infrastructure/Requests/Logger';
import {RouteChildrenProps} from 'react-router-dom';
import {AdditionalConfiguration, EcologProXGWizardState, EditXGXWizardState, IssueProfile, SensorState} from '../../AddSensor/WizardState';
import {ViewHeader} from '../../../../Common';
import {Button, Col} from 'antd';
import {LimitProfileService, SensorService} from '../../../../../common/services';
import {SensorsAllInformationsViewRow} from '../../../../../common/types/DbModel';
import {AlarmLimit, User} from '../../../../../common/types';
import {UserServiceHelper} from '../../../../../common/services/UserService/UserServiceHelper';
import {CalculateDeviceChecksum} from '../../../../../common/services/WebserverServicePro/DeviceService';
import {getAdditionallyAffectedSensorsAsync} from '../../../../../common/util/SensorChannelGrouping';
import {Sensor} from '../../../../../components/Common/Types/Sensor';

class SensorShowSummary extends Component<
	RouteChildrenProps,
	{
		loading: boolean;
		sensorLicense?: unknown;
		sensor_state?: unknown;
		additional_configuration?: unknown;
	} & SensorState &
		EcologProXGWizardState &
		Omit<AdditionalConfiguration, 'configuration_template_result'> &
		Pick<EditXGXWizardState, 'templateName'>
> {
	declare context: React.ContextType<typeof MultiContext>;
	static contextType = MultiContext;

	constructor(props) {
		super(props);

		this.state = {
			formStates: null,
			issueProfiles: [],
			loading: true,
			templateName: null,
			moduleForm: null,
			sensorBasicsForm: null,
			metadatasForm: null,
			sensorIssuesForm: null,
			summaryForm: {
				disable_sensor_update_msg: '',
				errorMessage: '',
				offering: undefined,
				start_sensor: false,
				terms_of_use: false,
				vouchers: [],
			},

			// Configuration
			has_additional_configuration: false,
			configuration_templates: null,
			configuration_template: null,
			communication_mode_templates: null,
			additional_configuration_forms: {},
		};
	}

	componentDidMount() {
		let that = this;

		let gets = [
			SensorService.AllInformation(this.props.match.params['id']),
			LimitProfileService.GetLimitProfiles(),
			IssueProfileService.issueProfiles({}, RequestLogger.createLogData('sensor-summary', 'load-issue-profiles', 'onLoad')),
			LicensingService.getSensorLicense(
				this.props.match.params['id'],
				RequestLogger.createLogData('sensor-summary', 'load-licence', 'onLoad')
			),
			LegacySensorService.discoverModule(
				{serial_number: that.props.location.state['serial_number']},
				RequestLogger.createLogData('sensor-summary', 'load-discover-module', 'onLoad')
			),
			UserServiceHelper.GetAllUsers(),
		];

		Promise.all(gets).then(responses => {
			const sensorData = responses[0] as SensorsAllInformationsViewRow;
			const limitProfiles = responses[1] as AlarmLimit[];
			const issueProfiles = responses[2].data as IssueProfile[];
			const sensorLicense = responses[3].data[0];
			const moduleData = responses[4].data;
			const users = responses[5] as User[];

			that.setState(prevState => ({
				summaryForm: {
					...prevState.summaryForm,
					sensorLicense: sensorLicense,
				},
			}));

			const selectedChannel =
				moduleData.module && moduleData.module.used_channels
					? moduleData.module.used_channels.find(channel => channel.sensors_id.toString() === this.props.match.params['id'])
					: null;

			const currentModuleChannels = {channelsUsed: [selectedChannel], channelsFree: []};

			const configTemplateName = findModuleFamilyType(
				moduleData.module.configuration_templates !== undefined ? moduleData.module.configuration_templates[0].name : null
			);

			function findModuleFamilyType(templateName) {
				if (templateName !== null && templateName !== undefined && templateName.includes('ECOLOG-PRO')) {
					return MODULE_FAMILIES.ECOLOG_PRO_G;
				} else if (templateName !== null && templateName !== undefined && templateName.includes('LIBERO')) {
					return MODULE_FAMILIES.LIBERO_G;
				}
			}

			const parsedData = sensorData.metadata;

			that.setState({
				additional_configuration_forms: undefined,
				configuration_template: undefined,
				configuration_templates: [],
				formStates: {
					predictiveForm: undefined,
					limitAlarmForms: [
						{
							alarmLimit: limitProfiles.find(l => l.Id == sensorData.sensor_limit_alarm_id),

							recipients: {
								email: {
									enabled: sensorData.sensor_limit_email_notification,
									recipients:
										sensorData.recipients_email_list.limit_recipients?.map((id: number) =>
											users.find(u => u.Id == id)
										) ?? [],
								},
								sms: {
									enabled: sensorData.sensor_limit_sms_notification,
									recipients:
										sensorData.recipients_sms_list.limit_recipients?.map((id: number) => users.find(u => u.Id == id)) ??
										[],
								},
							},
							unit: sensorData.out_units_id,
						},
					],
				},
				has_additional_configuration: false,
				summaryForm: undefined,
				sensorLicense: sensorLicense,
				sensor_state: sensorData.state,
				templateName: configTemplateName,
				moduleForm: {
					serial_number: sensorData.serial_number,
				},
				sensorBasicsForm: {
					isConfiguration: true,
					//id: sensorData.id,
					sensor_name: sensorData.name,
					customer_id: sensorData.customer_id,
					logging_interval: sensorData.logging_interval,
					communication_interval:
						parsedData && parsedData.communication_intervals
							? parsedData.communication_intervals.interval_1
								? parsedData.communication_intervals.interval_1
								: parsedData.communication_intervals.interval_1
							: null,
					communication_mode: parsedData && parsedData.communication_mode ? parsedData.communication_mode : null,
					optimized_communication_behavior:
						parsedData && parsedData.fast_communication ? !!parsedData.fast_communication.enable : false,
					unit: sensorData.out_units_id,
					memo: sensorData.memo || '',
					firmware: sensorData.firmware || '',
					moduleData: moduleData,
					currentModuleChannels: currentModuleChannels,
					selectedChannel: selectedChannel,
					active: false,
					defaultSettingUnit: 0,
					disableChangeLogging: false,
					last_run_only: false,
				},
				metadatasForm: {
					sensor_name: sensorData.name,
					customer_id: sensorData.customer_id,
					memo: sensorData.memo,
				},
				sensorIssuesForm: {
					use_issue_alarms: sensorData.sensor_issue_alarm_id !== null,
					issue_alarm_creation_type: sensorData.sensor_issue_alarm_id || 0,
					email_notification: sensorData.sensor_issue_email_notification,
					sms_notification: sensorData.sensor_issue_sms_notification,
					editable: sensorData.sensor_issue_alarm_id === null,
					email_users: [],
					sms_users: [],
				},
				issueProfiles: issueProfiles,
				loading: false,
				communication_mode_templates: moduleData.module.communication_mode_templates,
				additional_configuration: sensorData.metadata || null,
			});

			if (sensorData.metadata !== null && sensorData.metadata !== undefined) {
				if (sensorData.metadata.configuration_templates_id)
					that.getConfigurationTemplate(sensorData.metadata.configuration_templates_id);
			}
		});
	}

	getConfigurationTemplate = (templateId: number) => {
		EditSensorService.configuration_template(templateId).then(response => {
			this.setState(
				{
					configuration_template: response.data.template,
					has_additional_configuration: true,
				},
				this.mapToAdditionalConfiguration
			);
		});
	};

	calculateCheckSum = async (): Promise<string> => {
		const sensorId = Number(this.props.match.params['id']);
		var additionallyAffectedSensor = await getAdditionallyAffectedSensorsAsync(new Sensor({id: sensorId, serial_number: this.state.moduleForm.serial_number}));
		var sensor_ids = [sensorId].concat(additionallyAffectedSensor.map(s => s.Id));

		return await CalculateDeviceChecksum(this.state.moduleForm.serial_number, sensor_ids);
	};

	close = e => {
		const DevicesViewSettings = this.context.AccessContext.user.user_settings?.devicesViewSettings ?? {view: '/dashboard'};
		this.props.history.push(DevicesViewSettings.view);
		e.preventDefault();
	};

	mapToAdditionalConfiguration() {
		let prevState = this.state;
		// Map Alarm limits to Display Settings Options
		const display_settings_template = prevState.configuration_template['display_settings'];

		if (
			this.state.templateName !== undefined &&
			this.state.templateName !== null &&
			this.state.templateName === MODULE_FAMILIES.ECOLOG_PRO_G
		) {
			this.setState(prev => ({
				configuration_template: {
					...prev.configuration_template,
					display_settings: {
						...display_settings_template,
						fields: {
							...display_settings_template.fields,
							in_transit: {
								...(display_settings_template.fields.in_transit || undefined),
								defaults: display_settings_template.fields.in_transit.defaults || undefined,
							},
						},
					},
				},
			}));
		} else if (
			this.state.templateName !== undefined &&
			this.state.templateName !== null &&
			this.state.templateName === MODULE_FAMILIES.ECOLOG_PRO_G
		) {
			this.setState(prev => ({
				configuration_template: {
					...prev.configuration_template,
					display_settings: {
						...display_settings_template,
						fields: {
							...display_settings_template.fields,
							before_start: {
								...(display_settings_template.fields.before_start || undefined),
								defaults: display_settings_template.fields.before_start.defaults || undefined,
							},
							in_transit: {
								...(display_settings_template.fields.in_transit || undefined),
								defaults: display_settings_template.fields.in_transit.defaults,
							},
							after_stop: {
								...(display_settings_template.fields.after_stop || undefined),
								defaults: [...(display_settings_template.fields.after_stop.defaults || [])],
							},
						},
					},
				},
			}));
		}
	}

	render() {
		const IsTemplateStillLoading = () => !this.state.configuration_template; //as long as its not completely loaded

		let loading =
			this.state.loading ||
			((SerialNumberInfo.isValidLiberoG(this.state.moduleForm.serial_number) ||
				SerialNumberInfo.isValidEcologProG(this.state.moduleForm.serial_number)) &&
				IsTemplateStillLoading());

		if (loading) {
			return <div>Loading...</div>;
		} else
			return (
				<ViewWrapper>
					<ViewHeader heading={'Sensor Summary'}>
						<Col>
							<Button type="primary" onClick={this.close} size="large">
								<em className="fsInButton elpro-Close fa-fw" />
								<Trans i18nKey={'buttons.close'} />
							</Button>
						</Col>
					</ViewHeader>
					<ContentWrapper>
						<SensorSummaryFormComponent
							formStates={this.state.formStates}
							calculateChecksum={this.calculateCheckSum}
							pairingOnly={false}
							moduleForm={this.state.moduleForm}
							sensorBasicsForm={this.state.sensorBasicsForm}
							metadatasForm={this.state.metadatasForm}
							sensorIssuesForm={this.state.sensorIssuesForm}
							configuration_template={this.state.configuration_template}
							additional_configuration={this.state.additional_configuration}
							communication_mode_templates={this.state.communication_mode_templates}
							summaryForm={this.state.summaryForm}
							issueProfiles={this.state.issueProfiles}
							setOfferingObject={undefined}
							getOffering={undefined}
							offering={undefined}
							onBack={undefined}
							onUpdateSensor={undefined}
							onBuyAndPairSensor={undefined}
							handleTermsOfUse={undefined}
							hideBuy={true} //is always just a view
							hideSummaryHeaderDiv={true}
							templateName={this.state.templateName}
							sensorSummarySettings={getSensorSummarySettings(this.state.moduleForm.serial_number)}
						/>
					</ContentWrapper>
				</ViewWrapper>
			);
	}
}

export default SensorShowSummary;
