import {SensorBasicsForm, SensorIssuesForm, WizardState} from '../../Dashboard/Sensor/AddSensor/WizardState';
import {FeatureFlag, FeatureFlagHelper} from '../../../common/helpers';
import {LicensingService, OfferRequest, OfferResponse, SubscriptionCancellationRequest} from '../../../common/services/LicensingService';
import {LicenseOffering, LineItemsExtension, ProfileLineItemsResult} from '../../../common/util/LicenseUtils/LineItemsExtension';
import {IsLineItemForSelectedSensor} from './UtilFunctions';
import {
	ActionType,
	PositionedSensor,
	PositionedSensors,
	InternalProductCategories,
	ConfigurationOptions,
	ConfigurationOption,
} from '../../../common/util/LicenseUtils/LineItemConstants';
import {
	CheckoutSessionCreationRequestProps,
	LicensingExtension,
	SubscriptionUpdateRequestProps,
} from '../../../common/util/LicenseUtils/LicensingExtension';
import {DeviceUtils} from '../../../common/util';
import {DeviceFamily, DeviceType} from '../../../common/constants';
import {LimitAlarmFormState} from 'components/Dashboard/Sensor/Forms/LimitAlarmForm/LimitAlarmFormState';
import appSettings from '../../Infrastructure/Settings/AppSettings';
import Message from '../../Shared/Components/Message';
import {LineItem} from '../../../common/util/LicenseUtils/LineItem';

const useLocalhost = process.env?.NODE_ENV === 'development' && process.env.REACT_APP_SERVICE_USE_LOCALHOST?.includes('FRONTEND');
const baseUrl: string = useLocalhost ? 'http://localhost:4000' : appSettings.getFrontendBaseUrl();

export async function GetInitialLicenseOfferingResult(
	moduleId: string,
	serialNumber: string,
	profileIndex: number,
	sensorBasicsForm: SensorBasicsForm,
	limitAlarmFormState: LimitAlarmFormState,
	sensorIssuesForm: SensorIssuesForm
): Promise<Pick<WizardState, 'licenseOffering' | 'hasSubscription' | 'licenseOfferingResult'>> {
	if (await FeatureFlagHelper.FeatureFlagIsEnabled(FeatureFlag.Licensing)) {
		let newState: Pick<WizardState, 'licenseOffering' | 'hasSubscription' | 'licenseOfferingResult'> = {
			hasSubscription: false,
			licenseOffering: {
				baseLicenses: [],
				loggingIntervals: [],
				limitEmail1: undefined,
				limitEmail2: undefined,
				limitSms1: undefined,
				limitSms2: undefined,
				issueEmail: undefined,
				issueSms: undefined,
				retirement1: undefined,
				retirement2: undefined,
			},
			licenseOfferingResult: undefined,
		};

		try {
			const subscription = await LicensingService.GetSubscription(moduleId);
			newState.hasSubscription = true;
			let lineItems = subscription.lineItems;

			if (DeviceUtils.GetDeviceFamily(serialNumber) == DeviceFamily.EcologProRadio) {
				lineItems = lineItems.filter(l =>
					IsLineItemForSelectedSensor(
						l,
						sensorBasicsForm.selectedChannel.selectedChannel,
						sensorBasicsForm.selectedChannel.selectedValueIndex
					)
				);
			}
			if (lineItems.length > 0) {
				newState.licenseOffering = LineItemsExtension.mapLineItemsToLicenseOffering(lineItems);
			}
			return newState;
		} catch (e) {
			console.error('Error occurred while getting subscription:', e);
		}

		try {
			const licenseOfferingUpdate = await GetLicensingOfferingUpdate(
				moduleId,
				serialNumber,
				newState.hasSubscription,
				newState.licenseOffering,
				sensorBasicsForm,
				profileIndex,
				limitAlarmFormState,
				sensorIssuesForm
			);
			return {...licenseOfferingUpdate, hasSubscription: newState.hasSubscription};
		} catch (e) {
			console.error('Error occurred while updating license offering:', e);
		}
		return newState;
	}
}

export async function PrepareLicenseOfferingFromLineItemTypes(
	serialNumber: string,
	selectedChannel: number,
	selectedValueIndex: number,
	licenseOffering: LicenseOffering,
	categories: string[],
	profileIndex?: number,
	limitAlarmFormState?: LimitAlarmFormState,
	sensorIssuesForm?: SensorIssuesForm,
	loggingInterval?: number
): Promise<LicenseOffering> {
	try {
		const deviceType = DeviceUtils.GetDeviceType(serialNumber);

		if (categories.some(c => c === InternalProductCategories.BaseIPC)) {
			const lineItemTypes = await LicensingService.GetLineItemTypes([InternalProductCategories.BaseIPC], [deviceType]);

			const lineItems: LineItem[] = LicensingExtension.PrepareLineItemsFromLineItemTypes(
				lineItemTypes,
				deviceType,
				selectedChannel,
				selectedValueIndex
			);

			licenseOffering.baseLicenses = lineItems;
		}

		if (categories.some(c => c === InternalProductCategories.LoggingIntervalIPC)) {
			const lineItemTypes = await LicensingService.GetLineItemTypes([InternalProductCategories.LoggingIntervalIPC], [deviceType]);

			const loggingIntervalInMinutes = (loggingInterval / 60).toString() + '-min';

			const lineItems: LineItem[] = LicensingExtension.PrepareLineItemsFromLineItemTypes(
				lineItemTypes.filter(lit => lit.internalPrice.includes(loggingIntervalInMinutes)),
				deviceType,
				selectedChannel,
				selectedValueIndex
			);

			licenseOffering.loggingIntervals = lineItems;
		}

		if (categories.some(c => c === InternalProductCategories.LimitProfileEmailIPC && InternalProductCategories.LimitProfileSmsIPC)) {
			licenseOffering = await GetLimitProfileLicenseOffering(
				deviceType,
				selectedChannel,
				selectedValueIndex,
				profileIndex,
				!!limitAlarmFormState?.alarmLimit,
				limitAlarmFormState?.recipients?.email.enabled,
				limitAlarmFormState?.recipients?.sms.enabled,
				licenseOffering
			);
		}

		if (categories.some(c => c === InternalProductCategories.IssueProfileEmailIPC && InternalProductCategories.IssueProfileSmsIPC)) {
			licenseOffering = await GetIssueProfileLicenseOffering(
				deviceType,
				selectedChannel,
				selectedValueIndex,
				profileIndex,
				sensorIssuesForm?.use_issue_alarms,
				sensorIssuesForm?.email_notification,
				sensorIssuesForm?.sms_notification,
				licenseOffering
			);
		}

		if (categories.some(c => c === InternalProductCategories.RetirementIPC)) {
			licenseOffering = await GetRetirementLicenseOffering(deviceType, selectedChannel, selectedValueIndex, licenseOffering);
		}
	} catch (e) {
		console.error(e);
	}

	return licenseOffering;
}

export async function GetLicenseOffering(
	moduleId: string,
	hasSubscription: boolean,
	licenseOffering: LicenseOffering
): Promise<OfferResponse> {
	const offerRequestProps = {
		moduleId: moduleId,
		actionType: hasSubscription ? ActionType.Upgrade : ActionType.Get,
		lineItemProps: {
			baseLicenses: licenseOffering?.baseLicenses,
			loggingIntervals: licenseOffering?.loggingIntervals,
			limitEmail1: licenseOffering?.limitEmail1,
			limitEmail2: licenseOffering?.limitEmail2,
			limitSms1: licenseOffering?.limitSms1,
			limitSms2: licenseOffering?.limitSms2,
			issueEmail: licenseOffering?.issueEmail,
			issueSms: licenseOffering?.issueSms,
			retirement1: licenseOffering?.retirement1,
			retirement2: licenseOffering?.retirement2,
		},
	};

	const offerRequest: OfferRequest = LicensingExtension.prepareOfferRequest(offerRequestProps);

	try {
		if (offerRequest.lineItems.length > 0) {
			return await LicensingService.GetOffer(offerRequest);
		}
	} catch (e) {
		console.error(e);
	}
}

async function GetLicensingOfferingUpdate(
	moduleId: string,
	serialNumber: string,
	hasSubscription: boolean,
	currentLicenseOffering: LicenseOffering,
	sensorBasicsForm: SensorBasicsForm,
	profileIndex: number,
	limitAlarmFormState: LimitAlarmFormState,
	sensorIssuesForm: SensorIssuesForm
): Promise<Pick<WizardState, 'licenseOffering' | 'licenseOfferingResult'>> {
	let result = {
		licenseOffering: undefined,
		licenseOfferingResult: undefined,
	};

	try {
		let licenseOffering = await PrepareLicenseOfferingFromLineItemTypes(
			serialNumber,
			sensorBasicsForm.selectedChannel.selectedChannel,
			sensorBasicsForm.selectedChannel.selectedValueIndex,
			currentLicenseOffering,
			[
				InternalProductCategories.BaseIPC,
				InternalProductCategories.LoggingIntervalIPC,
				InternalProductCategories.LimitProfileEmailIPC,
				InternalProductCategories.LimitProfileSmsIPC,
				InternalProductCategories.IssueProfileEmailIPC,
				InternalProductCategories.IssueProfileSmsIPC,
			],
			profileIndex,
			limitAlarmFormState,
			sensorIssuesForm,
			sensorBasicsForm.logging_interval
		);

		let licenseOfferingResult = await GetLicenseOffering(moduleId, hasSubscription, licenseOffering);

		result.licenseOffering = licenseOffering;
		result.licenseOfferingResult = licenseOfferingResult;
	} catch (e) {
		console.error(e);
	}

	return result;
}

async function GetLimitProfileLicenseOffering(
	deviceType: DeviceType,
	selectedChannel: number,
	selectedValueIndex: number,
	profileIndex: number,
	useLimitAlarms: boolean,
	emailNotification: boolean,
	smsNotification: boolean,
	currentLicenseOffering: LicenseOffering
): Promise<LicenseOffering> {
	try {
		let LineItemsResult: ProfileLineItemsResult = await LineItemsExtension.getLimitProfileLineItems(
			deviceType,
			selectedChannel,
			selectedValueIndex,
			profileIndex,
			useLimitAlarms,
			emailNotification,
			smsNotification
		);

		const sensorNumber = PositionedSensors[selectedChannel][selectedValueIndex];

		const configurationOption = ConfigurationOptions[deviceType];

		if (sensorNumber == PositionedSensor.FirstSensor) {
			currentLicenseOffering.limitEmail1 = LineItemsResult.emailLineItem;
			currentLicenseOffering.limitSms1 = LineItemsResult.smsLineItem;
			if (configurationOption == ConfigurationOption.Single) {
				currentLicenseOffering.limitEmail2 = null;
				currentLicenseOffering.limitSms2 = null;
			}
		} else {
			currentLicenseOffering.limitEmail2 = LineItemsResult.emailLineItem;
			currentLicenseOffering.limitSms2 = LineItemsResult.smsLineItem;
			if (configurationOption == ConfigurationOption.Single) {
				currentLicenseOffering.limitEmail1 = null;
				currentLicenseOffering.limitSms1 = null;
			}
		}
	} catch (e) {
		console.error('An error occurred while getting the license offering:', e);
		return null;
	}

	return currentLicenseOffering;
}

async function GetIssueProfileLicenseOffering(
	deviceType: DeviceType,
	selectedChannel: number,
	selectedValueIndex: number,
	profileIndex: number,
	useIssueAlarms: boolean,
	emailNotification: boolean,
	smsNotification: boolean,
	currentLicenseOffering: LicenseOffering
): Promise<LicenseOffering> {
	try {
		let lineItemsResult: ProfileLineItemsResult = await LineItemsExtension.getIssueProfileLineItems(
			deviceType,
			selectedChannel,
			selectedValueIndex,
			profileIndex,
			useIssueAlarms,
			emailNotification,
			smsNotification
		);

		currentLicenseOffering.issueEmail = lineItemsResult.emailLineItem;
		currentLicenseOffering.issueSms = lineItemsResult.smsLineItem;
	} catch (e) {
		console.error('An error occurred while getting the issue profile license offering:', e);
		return null;
	}

	return currentLicenseOffering;
}

async function GetRetirementLicenseOffering(
	deviceType: DeviceType,
	selectedChannel: number,
	selectedValueIndex: number,
	currentLicenseOffering: LicenseOffering
): Promise<LicenseOffering> {
	try {
		let lineItemsResult = await LineItemsExtension.getRetirementLineItems(deviceType, selectedChannel, selectedValueIndex);

		const sensorNumber = PositionedSensors[selectedChannel][selectedValueIndex];

		if (sensorNumber == PositionedSensor.FirstSensor) {
			currentLicenseOffering.retirement1 = lineItemsResult[0];
		} else {
			currentLicenseOffering.retirement2 = lineItemsResult[0];
		}

		return currentLicenseOffering;
	} catch (e) {
		console.error('An error occurred while getting the retirement license offering:', e);
		return null;
	}
}

export function SetUpCheckoutSession(
	moduleId: string,
	licenseOffering: LicenseOffering,
	pathName: string,
	history: any,
	devicesViewSettings: any
) {
	const checkoutSessionCreationRequestProps: CheckoutSessionCreationRequestProps = {
		moduleId: moduleId,
		successUrl: new URL(pathName, baseUrl).href,
		cancelUrl: new URL('addSensor', baseUrl).href,
		lineItemProps: {
			baseLicenses: licenseOffering?.baseLicenses,
			loggingIntervals: licenseOffering?.loggingIntervals,
			limitEmail1: licenseOffering?.limitEmail1,
			limitEmail2: licenseOffering?.limitEmail2,
			limitSms1: licenseOffering?.limitSms1,
			limitSms2: licenseOffering?.limitSms2,
			issueEmail: licenseOffering?.issueEmail,
			issueSms: licenseOffering?.issueSms,
			retirement1: licenseOffering?.retirement1,
			retirement2: licenseOffering?.retirement2,
		},
	};

	const checkoutSessionCreationRequest = LicensingExtension.prepareCheckoutSessionCreationRequest(checkoutSessionCreationRequestProps);

	LicensingService.CreateCheckoutSession(checkoutSessionCreationRequest)
		.then(r => {
			if (pathName.includes('dashboard')) {
				ShowSensorSettingsChangedMessage();
			}
			window.location.replace(r.url);
		})
		.catch(e => {
			console.error(e.data.message);
			history.replace({pathname: devicesViewSettings.view});
		});
}

export async function SetUpSubscriptionUpdate(
	moduleId: string,
	licenseOffering: LicenseOffering,
	pathName: string,
	history: any,
	devicesViewSettings: any
): Promise<void> {
	const subscriptionUpdateRequestProps: SubscriptionUpdateRequestProps = {
		moduleId: moduleId,
		lineItemProps: {
			baseLicenses: licenseOffering?.baseLicenses,
			loggingIntervals: licenseOffering?.loggingIntervals,
			limitEmail1: licenseOffering?.limitEmail1,
			limitEmail2: licenseOffering?.limitEmail2,
			limitSms1: licenseOffering?.limitSms1,
			limitSms2: licenseOffering?.limitSms2,
			issueEmail: licenseOffering?.issueEmail,
			issueSms: licenseOffering?.issueSms,
			retirement1: licenseOffering?.retirement1,
			retirement2: licenseOffering?.retirement2,
		},
	};

	const subscriptionUpdateRequest = LicensingExtension.prepareSubscriptionUpdateRequest(subscriptionUpdateRequestProps);

	LicensingService.UpdateSubscription(subscriptionUpdateRequest)
		.then(_ => {
			if (pathName.includes('dashboard')) {
				ShowSensorSettingsChangedMessage();
			}
			history.push({pathname: pathName});
		})
		.catch(e => {
			console.error(e.data.title);
			history.replace({pathname: devicesViewSettings.view});
		});
}

export function ShowSensorSettingsChangedMessage() {
	Message.success('Sensor settings changed', 'Sensor settings successfully changed');
}

function CancelSubscription(moduleId: string) {
	const subscriptionCancellationRequest: SubscriptionCancellationRequest = {
		internalProductId: moduleId,
	};

	LicensingService.CancelSubscription(subscriptionCancellationRequest)
		.then(r => console.log('Subscription cancellation successful. Status: ' + r.status))
		.catch(e => console.error('Error occurred while cancelling the subscription:', e));
}

if (process.env['BABEL_ENV'] === 'test' || process.env['NODE_ENV'] === 'test') {
	//Functions are only visible for testing purpose.
	module.exports.CancelSubscription = CancelSubscription;
}
