import {History} from 'history';
import {Paths} from '../constants';
import {SensorService} from '../services';
import CalibrationService from '../../components/Services/Calibrations/CalibrationService';
import {getAdditionallyAffectedSensorsAsync} from '../util/SensorChannelGrouping';
import {DeviceUtils} from '../util';
import {UI_EVENT} from '../../components/Shared/Constants/Events';
import pubsub from 'pubsub-js';
import {Sensor} from '../../components/Common/Types/Sensor';
import Shared from '../../components/Shared/Shared';
import fileSaver from 'file-saver';
import Message from '../../components/Shared/Components/Message';
import i18next from 'i18next';

export class SensorActions {
	public static Analyse(sensor: Sensor, history: History) {
		history.push(`${Paths.Sensor.Analysis}/${sensor.Id}`, {sensor: sensor});
	}

	public static async ConfigureSensor(sensor: Sensor, history: History): Promise<void> {
		if (DeviceUtils.IsEcologProXG(sensor.SerialNumber) || DeviceUtils.IsLiberoGx(sensor.SerialNumber)) {
			const pathBase = DeviceUtils.IsLiberoGx(sensor.SerialNumber) ? '/startNewRun/' : '/editEcologProGx/';
			try {
				const {AffectedSensors, Configuration} = await SensorService.DiscoverRun(sensor.Id);
				history.push(pathBase + sensor.Id, {
					serial_number: sensor.SerialNumber,
					additional_configuration: Configuration,
					sensors: AffectedSensors,
				});
				return;
			} catch (e) {
				return await Promise.reject(e);
			}
		}

		history.push('/editSensor/' + sensor.Id, {serial_number: sensor.SerialNumber, sensor_state: sensor.State});
	}

	public static async DownloadCertificate(sensor: Sensor, i18n: i18next.i18n): Promise<void> {
		const url = `https://compliance.elpro.com/api/getcertificate?requestId=8bbafa04-d268-48ff-b63d-818331bf3b6b&loggerIds=${sensor.SerialNumber}&ip`;

		const reqHeaders = new Headers();
		reqHeaders.append('Authorization', 'Basic RWxwcm9MaWJlcm9Qb3J0YWw6VUhWaWJHbGpWMlZpUm1GallXUmxVMlZ5ZG1salpUSXdNakF0TURndE1EYz0=');

		const reqOptions: RequestInit = {
			method: 'GET',
			headers: reqHeaders,
			redirect: 'follow',
		};

		fetch(url, reqOptions)
			.then(response => {
				return response.json();
			})
			.then(res => {
				if (res.fileBin === null) {
					Message.toastErr('error.certificateDownloadFailed', i18n);
					return;
				}

				const file = Shared.base64toFile(res.fileBin, res.filename, 'application/pdf');
				fileSaver.saveAs(file, res.filename);
			})
			.catch(error => console.error('error', error));
	}

	public static ManageLicense(history: History) {
		history.push('/licenses');
	}

	public static ReplaceSensor(sensor: Sensor, history: History) {
		history.push('/replaceSensor/' + sensor.Id, {serial_number: sensor.SerialNumber});
	}

	public static async SetAlarmingEnabled(sensor: Sensor, alarmingEnabled: boolean): Promise<void> {
		return await SensorService.SetAlarmingEnabled(sensor, alarmingEnabled);
	}

	public static ShowSummary(sensor: Sensor, history: History) {
		history.push('/sensorShowSummary/' + sensor.Id, {serial_number: sensor.SerialNumber});
	}

	public static async StopCalibration(sensor: Sensor): Promise<void> {
		const calibrationService = new CalibrationService();
		let affectedSensors: Sensor[] = [];
		try {
			await calibrationService.tryStopCalibration(sensor.Id);
			affectedSensors = await getAdditionallyAffectedSensorsAsync(sensor);
		} catch (e) {
			return await Promise.reject(e);
		}

		pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${sensor.Id}`, {
			sensorId: sensor.Id,
		});

		affectedSensors.forEach(affectedSensor => {
			pubsub.publish(`${UI_EVENT.CALIBRATION_STATUS_CHANGED}.${affectedSensor.Id}`, {
				sensorId: affectedSensor.Id,
			});
		});
	}
}
