import React, {Component} from 'react';
import {debounce} from 'lodash';
import {Col, ColProps, Row, Select, Space, Spin} from 'antd';
import LegacySensorService from './Sensor/SensorService';
import SensorComponent from './Sensor/SensorComponent';
import pubsub from 'pubsub-js';
import Message from '../Shared/Components/Message';
import {Access} from '../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../Infrastructure/Authorization/Access';
import SerialNumberInfo from '../Shared/SerialNumberInfo';
import {RequestLogger} from '../Infrastructure/Requests/Logger';
import {MultiContext} from '../Infrastructure/Authorization/Context/MultiContext';
import {RouteComponentProps} from 'react-router-dom';
import {DashboardState} from './DashboardState';
import {OptimizeFor} from './Sensor/SensorBodyGraphic';
import {
	CalibrationStatusChanged,
	DEVICE_EVENT,
	RunStatusChanged,
	SensorAlarmingEnabledChangedData,
	UI_EVENT,
} from '../Shared/Constants/Events';
import {Sensor} from '../Common/Types/Sensor';
import {MeasurementNew} from '../Common/Types/WebSocket/MeasurementNew';
import {SignalStrengthNew} from '../Common/Types/WebSocket/SignalStrengthNew';
import InfiniteScroll from 'react-infinite-scroller';
import {ComputePageNumber} from '../../common/util';
import {groupModules} from '../../common/util/SensorChannelGrouping';
import {ContentWrapper, ViewWrapper} from '../Layout';
import {ViewHeader} from '../Common';
import Shared from '../Shared/Shared';
import {AddSensorButton} from '../Buttons';
import {SensorGroupService, SensorService} from '../../common/services';
import {IServerSideGetRowsRequest} from 'ag-grid-community';
import {ToOutUnit} from '../../common/util/MeasurementValueUtils';
import Search from 'antd/es/input/Search';
import {SensorInfo} from '../../components/Services/Sensors/SensorInfo';

const rowWrapper = {
	margin: '0',
};

const rowChild = {
	...rowWrapper,
	width: '100%',
};

const colGroupParent = {
	padding: '1px',
	margin: '0px',
};

const rowGroupWrapper = {
	backgroundColor: 'white',
	border: '2px solid #3a3a39',
	borderRadius: '5px',
	margin: '1px',
	padding: '1px',
};

const colGroupChild = {
	padding: '2px 5px 0px 4px',
};

const colGroupChildLast = {
	padding: '3px 2px 3px 8px',
};

const colSingleParent = {
	padding: '1px',
	margin: '0px',
};

const rowSingleWrapper = {
	backgroundColor: 'white',
	border: '2px solid white ',
	margin: '1px',
	padding: '0px',
};

const colSingleChild = {
	padding: '4px',
};

const OptimizeForSpeedThreshold = 50;

class Dashboard extends Component<RouteComponentProps, DashboardState> {
	static contextType = MultiContext;

	declare context: React.ContextType<typeof MultiContext>;

	private readonly ItemsPerPage = 50;
	private loading = false;
	private totalSensors: number = undefined;
	private filterModel: any = {};
	private sortModel: {sort: 'asc' | 'desc'; colId: string} = {colId: 'id', sort: 'desc'};

	private subscriptions: PubSubJS.Token[] = [];

	constructor(props) {
		super(props);

		this.state = {
			checkLastRunOnly: props.location.state && props.location.state.checkLastRunOnly,
			loadedPage: undefined,
			sensorGroups: [],
			shownSensors: [],
			deviceStates: [],
			deviceFamilies: [],
		};

		this.handleNewMeasurement = this.handleNewMeasurement.bind(this);
		this.updateShownSensors = this.updateShownSensors.bind(this);
		this.refreshViewData = this.refreshViewData.bind(this);
	}

	componentDidMount() {
		this.saveDevicesViewSettings();

		this.subscriptions.push(
			...[
				pubsub.subscribe(DEVICE_EVENT.MEASUREMENT_NEW, this.handleNewMeasurement),
				pubsub.subscribe(DEVICE_EVENT.SENSOR_NEW, () => this.refreshViewData()),
				pubsub.subscribe(DEVICE_EVENT.SENSOR_DELETED, () => this.refreshViewData()),
				pubsub.subscribe(DEVICE_EVENT.RUN_STATUS_CHANGED, (_msg: string, data: RunStatusChanged) =>
					this.refreshViewData(data.sensor_id)
				),
				pubsub.subscribe(DEVICE_EVENT.UPDATE_DASHBOARD_LIBERO_G, (_msg: string, data: {sensors_id: number}) =>
					this.refreshViewData(data.sensors_id)
				),
				pubsub.subscribe(DEVICE_EVENT.SIGNAL_STRENGTH_NEW, (_msg: string, data: SignalStrengthNew) =>
					this.refreshViewData(data.sensors_id)
				),
				pubsub.subscribe(
					UI_EVENT.SENSOR_ALARMING_ENABLED_CHANGED,
					async (_msg, data: SensorAlarmingEnabledChangedData) => await this.refreshViewData(data.SensorId)
				),
				pubsub.subscribe(
					UI_EVENT.CALIBRATION_STATUS_CHANGED,
					async (_msg, data: CalibrationStatusChanged) => await this.refreshViewData(data.sensorId)
				),
			]
		);

		Promise.all([SensorService.GetDeviceStates(), SensorService.GetSensorFamilies(), SensorGroupService.GetSensorGroups()]).then(
			([deviceStates, deviceFamilies, sensorGroups]) => {
				this.setState({
					deviceStates: deviceStates.map(d => ({value: d})),
					deviceFamilies: deviceFamilies.map(d => ({value: d})),
					sensorGroups: sensorGroups.map(d => ({value: d.Name})),
				});
			}
		);
	}

	componentWillUnmount() {
		this.subscriptions.forEach(token => {
			pubsub.unsubscribe(token);
		});
	}

	saveDevicesViewSettings() {
		const path = '/dashboard';
		Shared.saveDevicesViewSetting(this.context, path);
	}

	async refreshViewData(sensorId?: number) {
		if (sensorId && this.state.shownSensors.findIndex(s => s.id === sensorId) >= 0) {
			const updatedSensor = await SensorService.GetSensorInfo(sensorId);
			if (!updatedSensor) {
				return;
			}

			this.setState(prevState => {
				prevState.shownSensors[prevState.shownSensors.findIndex(s => s.id === sensorId)] = updatedSensor;
				return {shownSensors: prevState.shownSensors};
			});
		} else {
			this.updateShownSensors(this.state.loadedPage, true);
		}
	}

	handleNewMeasurement(_: string, data: MeasurementNew) {
		const sensorId = data.SensorId;
		this.setState(prevState => {
			let sensors = prevState.shownSensors;
			let updatedSensor = sensors.find(s => s.id === sensorId);
			if (updatedSensor === undefined) {
				return;
			}

			updatedSensor.last_measurement_tstamp = data.Timestamp.toUTCString();
			updatedSensor.last_measurement_value = ToOutUnit(data.Value, updatedSensor.out_units_id);
			updatedSensor.last_measurement_tilt_angle = data.TiltAngle;

			return {shownSensors: sensors};
		});
	}

	analyseSensor(sensor: SensorInfo) {
		this.props.history.push({
			pathname: '/sensorAnalysis/' + sensor.id,
			state: {sensor: sensor, checkLastRunOnly: this.state.checkLastRunOnly},
		});
	}

	sensorConfigurationLastStep(sensor: SensorInfo) {
		LegacySensorService.repairSensor(
			{_serial_nr: sensor.serial_number},
			RequestLogger.createLogData('dashboard', 'repair-sensor', 'onClick')
		)
			.then(() => {
				const prefixLength = SerialNumberInfo.isValidLiberoG(sensor.serial_number) ? 2 : 1;
				this.props.history.push({
					pathname:
						'/connectModule/' + sensor.id + '/' + sensor.serial_number + '/' + sensor.serial_number.substring(0, prefixLength),
				});
			})
			.catch(error => Message.error('Error', 'Sensor repair failed', error));
	}

	async handleSortChanged(sortProperty: string, sortDirection: 'asc' | 'desc') {
		this.sortModel = {colId: sortProperty, sort: sortDirection};
		await this.updateShownSensors(0, true);
	}

	async handleFilterChanged(filterProperty: string, filterType: string, value: any) {
		if (value && (filterType === 'set' ? value.length > 0 : true)) {
			this.filterModel[filterProperty] = {
				filterType,
				type: 'contains',
				...(filterType === 'set' ? {values: value} : {filter: value}),
			};
		} else {
			delete this.filterModel[filterProperty];
		}
		await this.updateShownSensors(0, true);
	}

	getOuterColProps(count: number): ColProps {
		if (count == 5 || count > 6) {
			count = 6;
		}
		return {
			xs: 24,
			sm: count * 12,
			md: count * 12,
			lg: count * 6,
			xl: count * 6,
			xxl: count * 4,
		};
	}

	getInnerColProps(count: number): ColProps {
		if (count == 5 || count > 6) {
			count = 6;
		}
		const computedSpan = 24 / count;
		return {
			xs: Math.max(24, computedSpan),
			sm: Math.max(12, computedSpan),
			md: Math.max(8, computedSpan),
			lg: Math.max(8, computedSpan),
			xl: Math.max(4, computedSpan),
			xxl: Math.max(4, computedSpan),
		};
	}

	renderSenorsList = (sensors: SensorInfo[]) => {
		const sensorsGrouped = groupModules(sensors.map(s => new Sensor(s)));

		const getSensorProps = sensor_info => {
			return {
				sensorConfigurationLastStep: () => this.sensorConfigurationLastStep(sensor_info),
				analyseSensor: () => this.analyseSensor(sensor_info),
				sensor: sensor_info,
			};
		};

		const optimizeFor = sensors.length > OptimizeForSpeedThreshold ? OptimizeFor.Speed : OptimizeFor.Quality;

		let sensorComponents: React.JSX.Element[] = [];
		sensorsGrouped.forEach((v, k, _m) => {
			sensorComponents.push(
				v.length > 1 ? (
					<Col key={`group-${k}`} style={colGroupParent} {...this.getOuterColProps(v.length)}>
						<Row style={rowGroupWrapper}>
							{v.map((sensorId, y) => {
								return (
									<Col
										key={`${sensorId}`}
										style={y === 1 ? colGroupChildLast : colGroupChild}
										{...this.getInnerColProps(v.length)}
									>
										<SensorComponent
											optimizeFor={optimizeFor}
											{...getSensorProps(sensors.find(s => s.id === sensorId))}
										/>
									</Col>
								);
							})}
						</Row>
					</Col>
				) : (
					<Col key={`${v[0]}`} style={colSingleParent} {...this.getOuterColProps(v.length)}>
						<Row style={rowSingleWrapper}>
							<Col key={k} style={colSingleChild} {...this.getInnerColProps(1)}>
								<SensorComponent optimizeFor={optimizeFor} {...getSensorProps(sensors.find(s => s.id === v[0]))} />
							</Col>
						</Row>
					</Col>
				)
			);
		});

		return sensorComponents;
	};

	async updateShownSensors(pageNr: number = ComputePageNumber(this.state.shownSensors.length, this.ItemsPerPage), reload = false) {
		if (this.loading || (this.state.loadedPage != null && pageNr <= this.state.loadedPage && !reload)) return;

		this.loading = true;

		if (reload) {
			this.totalSensors = undefined;
			this.setState({shownSensors: [], loadedPage: undefined});
		}

		const params: IServerSideGetRowsRequest = {
			startRow: pageNr * this.ItemsPerPage,
			endRow: pageNr * this.ItemsPerPage + this.ItemsPerPage,
			rowGroupCols: [],
			valueCols: [],
			pivotCols: [],
			pivotMode: false,
			groupKeys: [],
			filterModel: this.filterModel,
			sortModel: [this.sortModel],
		};

		const result = await SensorService.GetSensorsFromGridInfo(params);

		this.totalSensors = result.total;
		this.state.shownSensors.push(...result.sensors);

		this.setState({loadedPage: pageNr});
		this.loading = false;
	}

	render() {
		return (
			<ViewWrapper>
				<ViewHeader knowledgeHelpId={'010'} heading={'Dashboard'}>
					<Access access={accessPermissions.devicesview.child.dashboard.child.addSensor} roles={userRoles.default}>
						<Col>
							<AddSensorButton />
						</Col>
					</Access>
				</ViewHeader>
				<ContentWrapper>
					<Space direction={'vertical'}>
						<Access access={accessPermissions.devicesview.child.dashboard.child.selectSensorGroups} roles={userRoles.default}>
							<Row justify={'start'}>
								<Col xs={24} sm={12} md={12} lg={6}>
									<Search
										style={{width: '100%'}}
										onChange={debounce(
											(data: {target: {value: any}}) =>
												this.handleFilterChanged('searchable_text', 'text', data.target.value),
											500
										)}
										onSearch={value => this.handleFilterChanged('searchable_text', 'text', value)}
										allowClear={true}
									/>
								</Col>
								<Col xs={24} sm={0} md={0} lg={12} style={{padding: 1}} />
								<Col xs={18} sm={9} md={9} lg={4} style={{padding: 1}}>
									<Select
										showSearch
										placeholder="Sort By"
										options={[
											{value: 'id', label: 'Sensor Created'},
											{value: 'ts_start', label: 'Sensor Started'},
											{value: 'last_measurement_tstamp', label: 'Last Measurement'},
										]}
										defaultValue={'id'}
										style={{width: '100%'}}
										onChange={data => {
											this.handleSortChanged(data, this.sortModel.sort);
										}}
									/>
								</Col>
								<Col xs={6} sm={3} md={3} lg={2} style={{padding: 1}}>
									<Select
										showSearch
										placeholder="Sort Direction"
										options={[
											{value: 'asc', label: 'Asc'},
											{value: 'desc', label: 'Desc'},
										]}
										defaultValue={'desc'}
										style={{width: '100%'}}
										onChange={data => this.handleSortChanged(this.sortModel.colId, data as 'asc' | 'desc')}
									/>
								</Col>
							</Row>
							<Row justify={'start'}>
								<Col xs={24} sm={12} md={12} lg={6} style={{padding: 1}}>
									<Select
										showSearch
										placeholder="Device Family"
										filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
										options={this.state.deviceFamilies}
										style={{width: '100%'}}
										onChange={data => this.handleFilterChanged('module_family', 'set', data)}
										allowClear={true}
										mode="multiple"
									/>
								</Col>
								<Col xs={24} sm={12} md={12} lg={6} style={{padding: 1}}>
									<Select
										showSearch
										placeholder="Device State"
										filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
										options={this.state.deviceStates}
										style={{width: '100%'}}
										onChange={data => this.handleFilterChanged('device_state', 'set', data)}
										allowClear={true}
										mode="multiple"
									/>
								</Col>
								<Col xs={24} sm={12} md={12} lg={6} style={{padding: 1}}>
									<Select
										showSearch
										placeholder="Alarm State"
										filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
										options={[{value: 'None'}, {value: 'OK'}, {value: 'ALARM'}, {value: 'DEACTIVATED'}]}
										style={{width: '100%'}}
										onChange={data => this.handleFilterChanged('alarm_state', 'set', data)}
										allowClear={true}
										mode="multiple"
									/>
								</Col>
								<Col xs={24} sm={12} md={12} lg={6} style={{padding: 1}}>
									<Select
										showSearch
										placeholder="Sensor Group"
										filterOption={(input, option) => (option?.value ?? '').toLowerCase().includes(input.toLowerCase())}
										options={this.state.sensorGroups}
										style={{width: '100%'}}
										onChange={data => this.handleFilterChanged('sensor_groups_array', 'set', data)}
										allowClear={true}
										mode="multiple"
									/>
								</Col>
							</Row>
						</Access>
						<Row style={rowWrapper}>
							<InfiniteScroll
								style={rowChild}
								pageStart={this.state.loadedPage ?? 0}
								loadMore={() => this.updateShownSensors()}
								hasMore={!this.totalSensors || this.state.shownSensors.length < this.totalSensors}
								loader={<Spin key={'ininiteScrollLoader'} style={{display: 'block', margin: 'auto', marginTop: '20px'}} />}
							>
								<Row style={rowWrapper}>{this.renderSenorsList(this.state.shownSensors)}</Row>
							</InfiniteScroll>
						</Row>
					</Space>
				</ContentWrapper>
			</ViewWrapper>
		);
	}
}

export default Dashboard;
