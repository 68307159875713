import React, {Component} from 'react';
import {Alert, Col, Input, Row} from 'reactstrap';
import PropTypes from 'prop-types';
import SummaryTable from './SensorSummaryTable/SensorSummaryTableComponent';
import {HotKeys} from 'react-hotkeys';
import pubsub from 'pubsub-js';
import AntAlert from '../../../../Shared/Components/Alert';
import SystemSettings from '../../../../Infrastructure/Settings/SystemSettings';
import InputPaymentMethod from '../../Utils/InputPaymentMethod';
import OfferingTable from '../../Utils/OfferingTable';
import {LicenseOfferingTable} from '../../../../Licensing';
import styles from '../../../../Common/styles.module.css';
import {FeatureFlag, FeatureFlagHelper} from '../../../../../common/helpers';
import SensorTemplateExportComponent from './SensorTemplateExportComponent';
import {AuthContext} from '../../../../../common/context';
import {DeviceUtils} from '../../../../../common/util';

const keys = {
	showButtonSaveConfigurationButStartSensorLater: 'shift+s',
};

const handlers = {
	showButtonSaveConfigurationButStartSensorLater: event => pubsub.publish('showButtonSaveConfigurationButStartSensorLater'),
};

class SensorSummaryFormComponent extends Component {
	static contextType = AuthContext;

	constructor(props) {
		super(props);

		let that = this;

		if (
			this.props.sensorIssuesForm.use_issue_alarms &&
			this.props.sensorIssuesForm.issue_alarm_creation_type !== 0 &&
			this.props.sensorIssuesForm.editable === false
		) {
			let profile = undefined;

			this.props.issueProfiles.forEach(function (item) {
				if (item.id === that.props.sensorIssuesForm.issue_alarm_creation_type) {
					profile = item;
				}
			});

			if (profile !== undefined) {
				this.props.sensorIssuesForm.limit_issue_name = profile.name;
				this.props.sensorIssuesForm.missing_value_alarm = profile.missing_value_alarm; //Not used anymore
				this.props.sensorIssuesForm.missing_value_alarm_delay = profile.missing_value_alarm_delay; //Not used anymore
				this.props.sensorIssuesForm.lost_measurement_alarm = profile.lost_measurement_alarm;
				this.props.sensorIssuesForm.missing_communication_warning = profile.missing_communication_warning;
				this.props.sensorIssuesForm.missing_communication_warning_enum = profile.missing_communication_warning_enum;
				this.props.sensorIssuesForm.light_warning = profile.light_warning;
				this.props.sensorIssuesForm.tilt_warning = profile.tilt_warning;
				this.props.sensorIssuesForm.sensor_failure_alarm = profile.sensor_failure_alarm;
				this.props.sensorIssuesForm.sensor_failure_alarm_delay = profile.sensor_failure_alarm_delay;
				this.props.sensorIssuesForm.radio_connection_warning = profile.radio_connection_warning; //Not used anymore https://elproag.atlassian.net/browse/TH-460
				this.props.sensorIssuesForm.radio_connection_warning_delay = profile.radio_connection_warning_delay; //Not used anymore https://elproag.atlassian.net/browse/TH-460
				this.props.sensorIssuesForm.radio_connection_warning_threshold = profile.radio_connection_warning_threshold; //Not used anymore https://elproag.atlassian.net/browse/TH-460
				this.props.sensorIssuesForm.low_battery_warning = profile.low_battery_warning;
			} else {
				console.error('could not find profile id = ', this.props.sensorIssuesForm.issue_alarm_creation_type);
			}
		}

		this.state = {
			sensorSummarySettings: this.props.sensorSummarySettings,
			sensorPaymentMethodSettings: this.props.sensorPaymentMethodSettings,
			showButtonSaveConfigurationButStartSensorLater: false,
			errorMessage: undefined,
			showExportConfig: false,
			checksum: undefined,
		};

		this.inputPaymentMethod = null;
	}

	async componentDidMount() {
		var checksum = this.props.calculateChecksum ? await this.props.calculateChecksum() : undefined;
		var ff = await FeatureFlagHelper.GetFeatureFlags();

		this.setState({
			featureFlags: ff,
			checksum: checksum,
		});

		this.showButtonSaveConfigurationButStartSensorLaterToken = pubsub.subscribe(
			'showButtonSaveConfigurationButStartSensorLater',
			this.showButtonSaveConfigurationButStartSensorLater
		);
		this.catchRegistryExceptionsToken = pubsub.subscribe('catchRegistryExceptions', this.catchRegistryExceptions);
	}

	componentWillUnmount() {
		pubsub.unsubscribe(this.showButtonSaveConfigurationButStartSensorLaterToken);
		pubsub.unsubscribe(this.catchRegistryExceptionsToken);
	}

	showButtonSaveConfigurationButStartSensorLater = () => {
		this.setState({showButtonSaveConfigurationButStartSensorLater: !this.state.showButtonSaveConfigurationButStartSensorLater});
	};

	catchRegistryExceptions = (message, data) => {
		this.setState({errorMessage: 'Please check your inputs and try again.'});
	};

	getLicenseOfferings(prepaid_selected) {
		this.props.getLicenseOfferings && this.props.getLicenseOfferings(prepaid_selected);
	}

	hasError = (formName, inputName, method) => {
		return (
			this.state[formName] &&
			this.state[formName].errors &&
			this.state[formName].errors[inputName] &&
			this.state[formName].errors[inputName][method]
		);
	};

	back = e => {
		if (typeof this.props.onBack === 'function') {
			this.props.onBack();
		}
		e.preventDefault();
	};

	buyAndStart = e => {
		if (e.altKey) {
			this.setState({showExportConfig: true});
			e.preventDefault();
			return;
		}

		if (this.props.hideBuy || (this.state.featureFlags && this.state.featureFlags.includes(FeatureFlag.Licensing) == true)) {
			this.props.onBuyAndStart(this.createDefaultCreditCardObject());
			e.preventDefault();
		}

		if (this.inputPaymentMethod != null) {
			this.inputPaymentMethod.setCreditCardElementAndInvokeIfOK(
				this.props.onBuyAndStart,
				this.props.offering ? this.props.offering.actual_price : 0
			);
			e.preventDefault();
		}
	};

	buyAndPairSensor = e => {
		if (typeof this.props.onBuyAndPairSensor === 'function') {
			this.props.onBuyAndPairSensor(this.inputPaymentMethod?.creditCardElement);
		}
		e.preventDefault();
	};

	onSaveConfigurationButStartLater = e => {
		if (typeof this.props.onSaveConfigurationButStartLater === 'function') {
			this.props.onSaveConfigurationButStartLater();
		}
		e.preventDefault();
	};

	updateSensor = e => {
		if (typeof this.props.onUpdateSensor === 'function') {
			if (this.inputPaymentMethod != null) {
				this.inputPaymentMethod.setCreditCardElementAndInvokeIfOK(
					this.props.onUpdateSensor,
					this.props.offering ? this.props.offering.actual_price : 0
				);
			}
			if (this.state.featureFlags && this.state.featureFlags.includes(FeatureFlag.Licensing) == true) {
				this.props.onUpdateSensor(this.createDefaultCreditCardObject());
				e.preventDefault();
			}
		}
		e.preventDefault();
	};

	onSubmit = e => {
		const hasError = false;

		if (!hasError) {
			if (this.state.featureFlags && this.state.featureFlags.includes(FeatureFlag.Licensing) != true) {
				this.props.onNext();
			}
		} else {
			console.log('error');
		}

		e.preventDefault();
	};

	canBuy() {
		return this.props.summaryForm.terms_of_use && !Boolean(this.props.summaryForm.disable_sensor_update_msg);
	}

	wrap2Component = WrappedComponent => {
		class Wrapper extends React.PureComponent {
			render() {
				const {h3_heading, ...passThroughProps} = this.props;
				return (
					<div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<h3 style={{fontWeight: 'normal'}}>{h3_heading}</h3>
						<WrappedComponent {...passThroughProps} />
					</div>
				);
			}
		}

		return Wrapper;
	};

	createDefaultCreditCardObject() {
		return {
			id: null,
			credit_card_number: null,
			name_on_card: null,
			valid_until: null,
			client_ip: null,
			stripe_token: null,
			redeem_prepaid: null,
		};
	}

	generateExportConfigName() {
		const orgId = this.context.Organization.Id;
		const deviceType = DeviceUtils.GetDeviceType(this.props.moduleForm.serial_number);
		const datetime = new Date().toISOString().replace(/[-:]/g, '').replace(/T/, '_').split('.')[0];

		return orgId + '_' + deviceType + '_' + datetime;
	}

	paymentRequired() {
		return this.offering == undefined || this.offering.actual_price > 0 || this.props.offering?.lines?.some(o => o.actual_price < 0);
	}

	render() {
		const SummaryTableH3 = !this.props.hideSummaryHeaderDiv ? this.wrap2Component(SummaryTable) : SummaryTable;

		return (
			<>
				<SensorTemplateExportComponent
					isVisible={this.state.showExportConfig}
					configName={this.generateExportConfigName()}
					exportSensorConfigAsJson={name => this.props.exportSensorConfigAsJson(name, this.createDefaultCreditCardObject())}
					uploadSensorConfigAsTemplate={name => this.props.uploadSensorAsTemplate(name, this.createDefaultCreditCardObject())}
					onClose={() => this.setState({showExportConfig: false})}
				/>
				<HotKeys keyMap={keys} handlers={handlers}>
					<Col>
						<form name="formSummary" onSubmit={this.onSubmit}>
							<Row>
								<SummaryTableH3
									h3_heading="Summary"
									formStates={this.props.formStates}
									moduleForm={this.props.moduleForm}
									sensorBasicsForm={this.props.sensorBasicsForm}
									metadatasForm={this.props.metadatasForm}
									sensorIssuesForm={this.props.sensorIssuesForm}
									configuration_template={this.props.configuration_template}
									additional_configuration={this.props.additional_configuration}
									communication_mode_templates={this.props.communication_mode_templates}
									templateName={this.props.templateName}
									sensorSummarySettings={this.state.sensorSummarySettings}
									checksum={this.state.checksum}
								/>
							</Row>
							<br />
							{this.props.hideBuy ? null : (
								<>
									<Row className={styles.ComponentRow}>
										<Col sm={12} md={12} lg={12} xl={4}>
											<h3 style={{fontWeight: 'normal'}}>License</h3>
											{this.state.featureFlags && this.state.featureFlags.includes(FeatureFlag.Licensing) == true ? (
												<LicenseOfferingTable
													loading={this.props.licenseOfferingResult === undefined}
													offering={this.props.licenseOfferingResult}
												/>
											) : (
												<OfferingTable
													loading={!this.props.offering}
													vat_amount={this.props.offering ? this.props.offering.vat_amount : 0}
													lines={this.props.offering ? this.props.offering.lines : []}
													actual_price={this.props.offering ? this.props.offering.actual_price : 0}
													onRemoveElementByDescription={desc => {
														if (this.inputPaymentMethod) {
															this.props.summaryForm.vouchers = this.props.summaryForm.vouchers.filter(
																v => v.code !== desc
															);
															this.props.summaryForm.disable_sensor_update_msg = null;
															this.getLicenseOfferings(this.inputPaymentMethod.isPrepaidSelected());
														}
													}}
												/>
											)}
										</Col>
									</Row>
									<br />
									{this.state.featureFlags &&
										!this.state.featureFlags.includes(FeatureFlag.Licensing) &&
										this.paymentRequired() && (
											<Row>
												<Col sm={12} md={12} lg={12} xl={4}>
													<InputPaymentMethod
														selectedValue={this.props.summaryForm.credit_card}
														creditCard={this.props.summaryForm.credit_card}
														withNewCard={true}
														creditCardErrorMessage={this.props.summaryForm.creditCardErrorMessage}
														vouchers={this.props.summaryForm.vouchers}
														afterPaymentMethodAltered={(voucher, redeem_prepaid) => {
															if (voucher) {
																this.props.summaryForm.vouchers.push(voucher);
															}
															this.getLicenseOfferings(redeem_prepaid);
														}}
														onRef={ref => (this.inputPaymentMethod = ref)}
														paymentMethodSettings={this.state.sensorPaymentMethodSettings}
													/>
												</Col>
											</Row>
										)}
									<br />
								</>
							)}

							<div>
								{this.state.errorMessage ? <Alert color="danger text-center">{this.state.errorMessage}</Alert> : null}
							</div>

							{!this.props.hideBuy ? (
								<div className="clearfix">
									<div className="checkbox c-checkbox float-left mt-0">
										<label>
											<Input
												type="checkbox"
												onClick={this.props.handleTermsOfUse}
												value={this.props.summaryForm.terms_of_use}
												name="terms_of_use"
												defaultChecked={this.props.summaryForm.terms_of_use}
											/>
											<span className="fa fa-check" />I have read and agree with the{' '}
											<a href={SystemSettings.getConfig().privacy_policy} target="_blank">
												privacy policy
											</a>{' '}
											and{' '}
											<a href={SystemSettings.getConfig().terms_of_use} target="_blank">
												terms of use
											</a>
											.
										</label>
										<br />
										<br />
									</div>
								</div>
							) : null}

							<div>
								{typeof this.props.onBack === 'function' ? (
									<button type="button" className="btn btn-primary float-left" onClick={this.back}>
										<em className="fsInButton elpro-Back fa-fw" />
										Back
									</button>
								) : null}

								{typeof this.props.onBuyAndStart === 'function' ? (
									<button
										id={this.props.hideBuy ? 'btnSave' : 'btnBuyAndStart'}
										onClick={this.buyAndStart}
										className="btn btn-primary float-right"
										color="primary"
										disabled={this.props.hideBuy ? false : !this.canBuy()}
									>
										<em className="fsInButton elpro-Next fa-fw" />

										{this.props.hideBuy ? 'Apply Configuration' : 'Buy and Start Sensor'}
									</button>
								) : null}

								{typeof this.props.onBuyAndPairSensor === 'function' ? (
									<button
										id="btnSave"
										onClick={this.buyAndPairSensor}
										className="btn btn-primary float-right"
										color="primary"
										disabled={!this.canBuy()}
									>
										<em className="fsInButton elpro-Next fa-fw" />
										Buy and Start Sensor
									</button>
								) : null}

								{typeof this.props.onUpdateSensor === 'function' ? (
									<button
										id="btnSave"
										onClick={this.updateSensor}
										className="btn btn-primary float-right"
										color="primary"
										disabled={!this.canBuy()}
									>
										<em className="fsInButton elpro-Next fa-fw" />
										Save
									</button>
								) : null}

								{Boolean(this.props.summaryForm?.disable_sensor_update_msg) ? (
									<div style={{float: 'right', padding: '0 20px'}}>
										<AntAlert type="error" message={this.props.summaryForm.disable_sensor_update_msg} />
									</div>
								) : null}

								{typeof this.props.onSaveConfigurationButStartLater === 'function' &&
								this.state.showButtonSaveConfigurationButStartSensorLater ? (
									<button
										id="btnSaveStartLater"
										onClick={this.onSaveConfigurationButStartLater}
										className="btn btn-primary float-right"
										color="primary"
										disabled={!this.canBuy()}
									>
										<em className="fsInButton elpro-Next fa-fw" />
										Save Configuration but Start Sensor Later
									</button>
								) : null}
							</div>
						</form>
					</Col>
				</HotKeys>
			</>
		);
	}
}

SensorSummaryFormComponent.propTypes = {
	onBack: PropTypes.func,
	onNext: PropTypes.func,
	onUpdateSensor: PropTypes.func,
	onSaveConfigurationButStartLater: PropTypes.func,
	onBuyAndStart: PropTypes.func,
	uploadSensorAsTemplate: PropTypes.func,
	exportSensorConfigAsJson: PropTypes.func,
	moduleForm: PropTypes.object,
};

export default SensorSummaryFormComponent;
